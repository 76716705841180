import React from "react";

const Alert = ({ message }) => {
  return (
    <div className="flex justify-between space-x-2 px-3 sm:px-6 py-5 rounded-md bg-red-50 md:max-w-2xl md:mx-auto">
      <div className="flex">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="self-center ml-3 text-sm space-y-3">
          {/* <span className="block text-red-600 pt-[1px] font-semibold">
            Erreur
          </span> */}
          <div className="text-red-500 space-y-1" style={{ maxWidth: "100%" }}>
            <p style={{ maxWidth: "100%" }}>{message}</p>
          </div>
        </div>
      </div>
      {/* <button className="self-start text-red-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default Alert;
