import config from "../../config";

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

/**
 *
 * @param {*} payload
 * @returns consultation details
 */
export const createConsultation = async (payload, token) => {
  console.log("TOKEN: ", token);
  // myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };
  console.log("herer");
  try {
    const request = await fetch(
      config.API + "/api/consultation",
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("CreateConsultationServiceErr: ", error);
  }
};

export const getConsultations = async () => {
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/consultations",
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("GetConsultationsServiceErr: ", error);
  }
};

export const getConsultationsByFilter = async (payload, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/consultation/get-filter",
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("GetConsultationsFilterServiceErr: ", error);
  }
};

export const getConsultationById = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/consultations/" + id,
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("GetConsultationByIdServiceErr: ", error);
  }
};

export const deleteConsultation = async (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/consultations/" + id,
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("DeleteConsultationByIdServiceErr: ", error);
  }
};
