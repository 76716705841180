/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "react-clock/dist/Clock.css";
import { connect, useDispatch } from "react-redux";
import "react-time-picker/dist/TimePicker.css";
import { fetchAllActs } from "../../redux/acts/actions";
import {
  getConsultationsByFilter
} from "../../redux/consultations/actions";
import { getDiseasesByFilter } from "../../redux/diseases/actions";
import { getDrugsByFilter } from "../../redux/drugs/actions";
import {
  getUsers,
} from "../../redux/users/actions";
import { getStoragePatient } from "../../utils/storageUtils";
import DashboardWrapper from "../Layout/DashboardWrapper";
import TabsCustom from "./Tabs";

const storagePatient = getStoragePatient();

const Consultation = ({
  current_patient: curr_patient,
  errors,
}) => {
  const dispatch = useDispatch();
  const current_patient = Object.keys(curr_patient).length
    ? curr_patient
    : storagePatient;

  const [form, setForm] = useState({
    actsDiagnosticRequests: [
      {
        id: 0,
        actsId: null,
        eyeTypeName: "",
        result: "",
        resultFile: "",
      },
    ],
    dosageRequests: [
      {
        id: 0,
        drugsId: 0,
        frequency: "",
        quantity: 0,
      },
    ],
    doctorId: current_patient?.doctor?.id,
    patientId: current_patient?.id,
    appointmentTime: "10:00",
  });

  useEffect(() => {
  }, [form]);

  useEffect(() => {
    setForm({
      ...form,
      patientId: current_patient?.id,
      doctorId: current_patient?.doctor?.id,
    });
  }, []);
  console.log("curr_user: ", current_patient);

  useEffect(() => {
    dispatch(getDiseasesByFilter({}));
    dispatch(getDrugsByFilter({}));
    dispatch(fetchAllActs({ }));
    dispatch(getConsultationsByFilter({ "patient.id" : current_patient.id }));
    dispatch(getUsers());
    console.log("current_patient: ", current_patient);
  }, [current_patient]);

  return (
    <>
      <DashboardWrapper>
        <div className="px-4 md:px-10 py-28">
          <div className="space-y-8">
            <h3 className="text-lg sm:text-2xl text-slate-700 font-semibold">
              {current_patient?.firstName + " " + current_patient?.lastName}
            </h3>
            <div className="border p-4 rounded-md">
              <TabsCustom
                errors={errors}
              />
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = ({
  usersReducer,
  diseasesReducer,
  drugsReducer,
  actsReducer,
  consultationsReducer,
}) => ({
  current_patient: usersReducer.current_patient,
  users: usersReducer.users,
  diseases: diseasesReducer.diseases,
  drugs: drugsReducer.drugs,
  acts: actsReducer.acts,
  consultations: consultationsReducer.consultations,
  errors: consultationsReducer.errors,
});

export default connect(mapStateToProps)(Consultation);
