import * as types from './types';

const INITIAL_STATE = {
  creating_drug: false,
  error: '',
  getting_drugs: false,
  drugs: [],
  drugsSuivie: [],
  getting_drugs_suivie: false,
  creating_stock_drug: false,
  errors: []
};

function DrugsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CREATE_DRUG_REQUEST:
      return {
        ...state,
        creating_drug: true,
        errors: []
      };

    case types.CREATE_DRUG_SUCCESS:
      return {
        ...state,
        creating_drug: false,
        errors: []
      };
    case types.CREATE_DRUG_FAILURE:
      return {
        ...state,
        creating_drug: false,
        errors: action.payload
      };

      case types.CREATE_DRUG_STOCK_REQUEST:
      return {
        ...state,
        creating_stock_drug: true,
        errors: []
      };

    case types.CREATE_DRUG_STOCK_SUCCESS:
      return {
        ...state,
        creating_stock_drug: false,
        errors: []
      };
    case types.CREATE_DRUG_STOCK_FAILURE:
      return {
        ...state,
        creating_stock_drug: false,
        errors: action.payload
      };

    case types.GET_DRUGS_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_drugs: true
      };

    case types.GET_DRUGS_BY_FILTER_SUCCESS:
      return {
        ...state,
        getting_drugs: false,
        drugs: action.payload
      };
    case types.GET_DRUGS_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_drugs: false
      };

      case types.GET_DRUGS_STOCK_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_drugs_suivie: true
      };

    case types.GET_DRUGS_STOCK_BY_FILTER_SUCCESS:
      return {
        ...state,
        getting_drugs_suivie: false,
        drugsSuivie: action.payload
      };
    case types.GET_DRUGS_STOCK_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_drugs_suivie: false
      };

    default:
      return state;
  }
}

export default DrugsReducer;