/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const Pagination = ({ data, setData, maxDisplay = 10 }) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const paginationLength = Math.ceil(data.length / maxDisplay)

  const handleMove = (num) => {
    if(num < 1) return
    if(num > paginationLength) return

    // currentData = data[num * maxDisplay]
    const currentData = data.slice((num - 1) * maxDisplay, num * maxDisplay)
    console.log("CURRENT_LENGTH: ", currentData.length)
    setData(currentData)
  }

  useEffect(() => {
    setData(data.slice(0, maxDisplay))
  }, [])

  useEffect(() => {
    setData(data.slice(0, maxDisplay))
  }, [data])

  return (
    <div className="flex items-center justify-center">
      {paginationLength > 1 &&<ul className="flex flex-wrap mt-10">
        <li className="text-lg sm:text-2xl mb-[14px] sm:mb-[18px] mr-4 sm:mr-8">
          <a
            href="#"
            onClick={() => {
              if(currentIndex <= 1) return;

              handleMove(currentIndex - 1)
              setCurrentIndex(currentIndex - 1)
            }}
            className="flex items-center justify-center h-10 w-10 hover:bg-gray-200 text-slate-500 rounded-full transition-all ease-in-out duration-300"
          >
            <i className="bx bx-chevron-left"></i>
          </a>
        </li>
        {Array.from({ length: paginationLength }).map((_, i) => {
          if(i <= 10) {
            return (
              <li className="text-sm sm:text-base mb-[14px] sm:mb-[18px]">
              <a
                href="#"
                onClick={() => {
                  handleMove(i + 1)
                  setCurrentIndex(i + 1)
                }}
                className={`flex items-center justify-center h-10 w-10 hover:bg-gray-200 text-slate-500 rounded-full transition-all ease-in-out duration-300 ${currentIndex === (i + 1) && "bg-gray-200"}`}
              >
                {i + 1}
              </a>
            </li>
            )
          }
        }
        )}
        {paginationLength > 10 && <p style={{ alignSelf: "center", marginLeft: 10, marginBottom: 15}}>...</p>}
        <li className="text-lg sm:text-2xl mb-[14px] sm:mb-[18px] ml-4 sm:ml-8">
          <a
            href="#"
            onClick={() => {
              if(currentIndex >= paginationLength) return;

              handleMove(currentIndex + 1)
              setCurrentIndex(currentIndex + 1)
            }}
            className="flex items-center justify-center h-10 w-10 hover:bg-gray-200 text-slate-500 rounded-full transition-all ease-in-out duration-300"
          >
            <i className="bx bx-chevron-right"></i>
          </a>
        </li>
      </ul>}
    </div>
  );
};

export default Pagination;
