/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DashboardWrapper from "../Layout/DashboardWrapper";
import { connect, useDispatch } from "react-redux";
import {
  getDrugsByFilter,
  createDrug,
  updateDrug,
  deleteDrug,
  getDrugsStockByFilter,
  createStockDrug,
} from "../../redux/drugs/actions";
import { getUserData } from "../../utils/storageUtils";
import { redirect } from "react-router-dom";
import Alert from "../Others/Alert";
import DialogMui from "../material/Dailog";
import TabsIndex from "./TabsIndex";

const Pharmacy = ({
  drugs,
  creating_drug,
  getting_drugs,
  drugsSuivie,
  getting_drugs_suivie,
  errors,
}) => {
  const userData = getUserData();

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    stock: 0,
    basePrice: 0,
    quantity: 0,
    userId: userData.user.id,
    action: "create",
  });

  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.action === "create") {
      dispatch(createDrug(form));
    } else if (form.action === "edit") {
      dispatch(updateDrug(form));
    }

    document.getElementById("createFormDrugs").reset();
  };

  const handleStockSubmit = (e) => {
    e.preventDefault();
    console.log("FORM_STOCK: ", form);
    dispatch(createStockDrug(form));
  };

  const handleUpdate = (e, drug) => {
    e.preventDefault();
    setForm({ ...drug, action: "edit" });
    console.log("UPDATE: ", drug);
  };

  const handleDelete = (e, drug) => {
    e.preventDefault();
    console.log("DELETE: ", drug);
    // dispatch(deleteDrug(drug))
    setCurrentDrug(drug);
  };
  useEffect(() => {
    if (!userData) {
      return redirect("/login");
    }
    dispatch(getDrugsByFilter({}));
    dispatch(getDrugsStockByFilter({}));
    document.getElementById("createFormDrugs").reset();
  }, []);
  console.log("drugsSuivie: ", drugsSuivie);

  const [openDialog, setOpenDialog] = useState(false);
  const [currentDrug, setCurrentDrug] = useState();

  return (
    <>
      <DashboardWrapper
        showSpinner={creating_drug || getting_drugs || getting_drugs_suivie}
      >
        <DialogMui
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onCancel={() => setOpenDialog(false)}
          onConfirm={() => {
            // dispatch(deleteAct(actId))
            dispatch(deleteDrug(currentDrug));
            setOpenDialog(false);
          }}
        />
        <div className="px-4 md:px-10 py-28">
          <div className="space-y-8">
            <div>
              <TabsIndex
                form={form}
                errors={errors}
                drugs={drugs}
                drugsSuivie={drugsSuivie}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                setOpenDialog={setOpenDialog}
                handleStockSubmit={handleStockSubmit}
              />
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = ({ drugsReducer }) => ({
  drugs: drugsReducer.drugs,
  creating_drug: drugsReducer.creating_drug,
  getting_drugs: drugsReducer.getting_drugs,
  getting_drugs_suivie: drugsReducer.getting_drugs_suivie,
  drugsSuivie: drugsReducer.drugsSuivie,
  errors: drugsReducer.errors,
});

export default connect(mapStateToProps)(Pharmacy);
