import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import {
  createDrug as createDrugService,
  deleteDrug as deleteDrugService,
  getDrugsByFilter as getDrugsByFilterService,
  updateDrug as updateDrugService,
  getDrugsStockByFilter as getDrugsStockByFilterService,
  createDrugStock as createDrugStockService
} from "../../services/drugs";
import { getToken } from "../../utils/storageUtils";
import * as types from "./types";
import { adaptResponseError } from "../../utils/utils";

const token = getToken();

/**
 * @description create drug
 */
function* createDrug({ payload }) {
  try {
    const result = yield createDrugService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({ type: types.CREATE_DRUG_SUCCESS, payload: result?.data });
      yield put({ type: types.GET_DRUGS_BY_FILTER_REQUEST, payload: {} });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.CREATE_DRUG_FAILURE, payload: adaptResponseError(result) });
    }
  } catch (error) {
    console.error("CreateDrugSagaErr: ", error);
  }
}

function* createStockDrug({ payload }) {
  try {
    const result = yield createDrugStockService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({ type: types.CREATE_DRUG_STOCK_SUCCESS, payload: result?.data });
      yield put({ type: types.GET_DRUGS_STOCK_BY_FILTER_REQUEST, payload: {} });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.CREATE_DRUG_STOCK_FAILURE, payload: adaptResponseError(result) });
    }
  } catch (error) {
    console.error("CreateDrugSagaErr: ", error);
  }
}

function* getDrugsByFilter({ payload }) {
  try {
    const result = yield getDrugsByFilterService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({
        type: types.GET_DRUGS_BY_FILTER_SUCCESS,
        payload: result?.data || [],
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.GET_DRUGS_BY_FILTER_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("GetDrugsByFilterSagaErr: ", error);
  }
}

function* getDrugsStockByFilter({ payload }) {
  try {
    const result = yield getDrugsStockByFilterService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({
        type: types.GET_DRUGS_STOCK_BY_FILTER_SUCCESS,
        payload: result?.data || [],
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.GET_DRUGS_STOCK_BY_FILTER_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("GetDrugsStockByFilterSagaErr: ", error);
  }
}

function* updateDrug({ payload }) {
  try {
    const result = yield updateDrugService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({ type: types.UPDATE_DRUG_SUCCESS, payload: result?.data });
      yield put({ type: types.GET_DRUGS_BY_FILTER_REQUEST, payload: {} });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.UPDATE_DRUG_FAILURE, payload: adaptResponseError(result) });
    }
  } catch (error) {
    console.error("UpdateDrugSagaErr: ", error);
  }
}

function* deleteDrug({ payload }) {
  try {
    const result = yield deleteDrugService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({ type: types.DELETE_DRUG_SUCCESS, payload: result?.data });
      yield put({ type: types.GET_DRUGS_BY_FILTER_REQUEST, payload: {} });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.DELETE_DRUG_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("DeleteDrugSagaErr: ", error);
  }
}

export default function* DrugsSaga() {
  yield takeLatest(types.CREATE_DRUG_REQUEST, createDrug);
  yield takeLatest(types.GET_DRUGS_BY_FILTER_REQUEST, getDrugsByFilter);
  yield takeLatest(types.GET_DRUGS_STOCK_BY_FILTER_REQUEST, getDrugsStockByFilter);
  yield takeLatest(types.UPDATE_DRUG_REQUEST, updateDrug);
  yield takeLatest(types.DELETE_DRUG_REQUEST, deleteDrug);
  yield takeLatest(types.CREATE_DRUG_STOCK_REQUEST, createStockDrug);
}
