import * as types from "./types";

const INITIAL_STATE = {
  creating_consultation: false,
  error: "",
  getting_consultations: false,
  consultations: [],
  errors: []
};

function ConsultationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CREATE_CONSULTATION_REQUEST:
      return {
        ...state,
        creating_consultation: true,
        errors: []
      };
    case types.CREATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        creating_consultation: false,
        errors: []
      };
    case types.CREATE_CONSULTATION_FAILURE:
      return {
        ...state,
        creating_consultation: false,
        errors: action.payload
      };

    case types.GET_CONSULTATIONS_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_consultations: true,
      };
    case types.GET_CONSULTATIONS_BY_FILTER_SUCCESS:
      return {
        ...state,
        getting_consultations: false,
        consultations: action.payload
      };
    case types.GET_CONSULTATIONS_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_consultations: false,
      };

    default:
      return state;
  }
}

export default ConsultationsReducer;
