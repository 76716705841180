import * as types from "./types";

export const createActs = (payload) => ({
  type: types.CREATE_ACTS_REQUEST,
  payload,
});

export const fetchAllActs = (payload) => ({
  type: types.FETCH_ACTS_REQUEST,
  payload
});

export const deleteAct = (payload) => ({
  type: types.DELETE_ACTS_REQUEST,
  payload,
});

export const getActsPriceInsurerFilter = (payload) => ({
  type: types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST,
  payload,
});

export const createActsPriceInsurer = (payload) => ({
  type: types.CREATE_ACTS_PRICE_INSURER_REQUEST,
  payload
})

export const updateActsPriceInsurer = (payload) => ({
  type: types.UPDATE_ACTS_PRICE_INSURER_REQUEST,
  payload
})

export const deleteActsPriceInsurer = (payload) => ({
  type: types.DELETE_ACTS_PRICE_INSURER_REQUEST,
  payload
})