/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Alert from "../Others/Alert";
import Pagination from "../Others/Pagination";

const TabOne = ({ form, handleChange, handleSubmit, handleUpdate, drugs, handleDelete, setOpenDialog, errors }) => {
  const [search, setSearch] = useState("")
  const [data, setData] = useState(drugs)

  useEffect(() => {
    setData(drugs)
  }, [drugs])

  useEffect(() => {
    console.log("Data changed...")
  }, [data])

  return (
    <div className="space-y-8 lc-tab-target" data-tab="pharmacie">
      <h3 className="text-lg font-semibold sm:text-2xl text-slate-700">
        Pharmacie
      </h3>
      <div>
        {errors.length > 0 &&
          errors.map((error, idx) => (
            <div style={{ marginBottom: 2 }}>
              <Alert key={idx} message={error.message} />
            </div>
          ))}
      </div>
      <form
        id="createFormDrugs"
        className="block space-y-4 lg:flex md:items-end lg:justify-between lg:space-x-4 lg:space-y-0"
      >
        <label className="block grow basis-full">
          <span className="block text-sm font-medium text-slate-500">
            Médicament
          </span>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={form.name}
            className="w-full py-2 pl-3 mt-1 border-none rounded-md text-slate-500 pr-9 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
          />
        </label>
        <label className="block grow basis-full">
          <span className="block text-sm font-medium text-slate-500">
            Stock initial
          </span>
          <input
            type="number"
            name="stock"
            min="1"
            onChange={handleChange}
            value={form.stock}
            className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
          />
        </label>
        <label className="block grow basis-full">
          <span className="block text-sm font-medium text-slate-500">
            Base price
          </span>
          <input
            type="number"
            name="basePrice"
            min="1"
            onChange={handleChange}
            value={form.basePrice}
            className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
          />
        </label>
        <button
          type="button"
          onClick={handleSubmit}
          className="block py-2 pl-4 pr-4 text-white transition-all duration-300 ease-in-out bg-green-500 rounded-md grow basis-full hover:bg-green-600"
        >
          <span className="text-sm">Ajouter</span>
        </button>
        <button
          type="button"
          onClick={handleUpdate}
          className="block py-2 pl-4 pr-4 text-white transition-all duration-300 ease-in-out bg-green-500 rounded-md grow basis-full hover:bg-green-600"
        >
          <span className="text-sm">clear</span>
        </button>
      </form>
      <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
        <label className="relative w-full md:w-96">
          <span className="absolute left-2 top-[56%] -translate-y-2/4 text-slate-500 text-xl">
            <i className="bx bx-search"></i>
          </span>
          <input
            type="text"
            className="w-full pl-8 pr-6 text-sm bg-gray-100 border-none rounded-md sm:text-base text-slate-500 focus:bg-transparent focus:ring-1 focus:ring-gray-200 placeholder-slate-400"
            placeholder="Recherche"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </label>
        <div className="flex justify-end w-full mt-4 md:w-52 md:mt-0">
          <button className="lc-filter flex items-center text-gray-500 hover:bg-gray-200 border px-[15px] h-10 py-1.5 rounded-md transition-all ease-in-out duration-300">
            <i className="pr-1 text-lg bx bx-filter"></i>
            <span className="text-base">Filter</span>
          </button>
        </div>
      </div>
      <div className="hidden lc-filter-container">
        <div className="block space-y-4 md:flex md:items-center md:justify-between md:space-x-4 md:space-y-0">
          <label className="block grow basis-full">
            <span className="block text-sm font-semibold text-slate-700">
              Filter 1
            </span>
            <select className="w-full mt-1 text-sm border-none rounded-md text-slate-500 ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400">
              <option value="1">patient-1</option>
              <option value="2">patient-2</option>
              <option value="3">patient-3</option>
            </select>
          </label>
          <label className="block grow basis-full">
            <span className="block text-sm font-semibold text-slate-700">
              Filter 2
            </span>
            <select className="w-full mt-1 text-sm border-none rounded-md text-slate-500 ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400">
              <option value="1">patient-1</option>
              <option value="2">patient-2</option>
              <option value="3">patient-3</option>
            </select>
          </label>
          <label className="block grow basis-full">
            <span className="block text-sm font-semibold text-slate-700">
              Filter 3
            </span>
            <select className="w-full mt-1 text-sm border-none rounded-md text-slate-500 ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400">
              <option value="1">patient-1</option>
              <option value="2">patient-2</option>
              <option value="3">patient-3</option>
            </select>
          </label>
          <label className="block grow basis-full">
            <span className="block text-sm font-semibold text-slate-700">
              Filter 4
            </span>
            <select className="w-full mt-1 text-sm border-none rounded-md text-slate-500 ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400">
              <option value="1">patient-1</option>
              <option value="2">patient-2</option>
              <option value="3">patient-3</option>
            </select>
          </label>
        </div>
      </div>
      <div className="block w-full overflow-x-auto rounded-md">
        <table className="w-full mt-6">
          <tbody>
            <tr className="text-xs font-medium bg-slate-50 sm:text-sm text-slate-400">
              <td className="px-3 py-3">Médicaments</td>
              <td className="px-3 py-3 text-right">Prix de Base</td>
              <td className="px-3 py-3 text-right">Stock</td>
              <td className="px-3 py-3"></td>
            </tr>
            {data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(drug => drug?.name.toLowerCase().includes(search.toLocaleLowerCase())).map((drug, idx) => (
              <tr
                key={idx}
                className="text-xs font-medium transition-all duration-300 ease-in-out group/table sm:text-sm text-slate-500 hover:bg-blue-50"
              >
                <td className="px-3 py-3 border-b sm:py-6 border-b-gray-100 whitespace-nowrap">
                  {drug?.name}
                </td>
                <td className="px-3 py-3 text-right text-blue-500 border-b sm:py-6 border-b-gray-100 whitespace-nowrap">
                  {drug?.basePrice}
                </td>
                <td className="px-3 py-3 text-right text-blue-500 border-b sm:py-6 border-b-gray-100 whitespace-nowrap">
                  {drug?.stock}
                </td>
                <td className="px-3 py-3 border-b sm:py-6 border-b-gray-100">
                  <div className="flex items-center justify-end space-x-1">
                    <button
                      onClick={(e) => handleUpdate(e, drug)}
                      className="flex items-center justify-center w-8 h-8 text-lg transition-all duration-300 ease-in-out rounded-full hover:bg-amber-100 hover:text-amber-500"
                    >
                      <i className="bx bx-edit-alt"></i>
                    </button>
                    <button
                      onClick={(e) => {
                        handleDelete(e, drug);
                        setOpenDialog(true);
                      }}
                      className="flex items-center justify-center w-8 h-8 text-lg transition-all duration-300 ease-in-out rounded-full hover:bg-pink-100 hover:text-pink-500"
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination data={drugs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(drug => drug?.name.toLowerCase().includes(search.toLocaleLowerCase()))} setData={setData} />
    </div>
  );
};

export default TabOne;
