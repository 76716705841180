import * as types from "./types";

const INITIAL_STATE = {
  loging_user: false,
  error: "",
  user: { _id: "", lastName: "", username: "", firstName: "" },
  accounts: [{ id: 1, name: "MARC" }],
  users: [],
  getting_users: false,
  creating_user: false,
  profile: {},
  getting_profile: false,
  updating_user: false,
  current_patient: {},
  current_insurer: {},
  errors: []
};

function UsersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOGIN_USER_REQUEST:
      return {
        ...state,
        loging_user: true,
      };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loging_user: false,
      };
    case types.LOGIN_USER_FAILURE:
      return {
        ...state,
        loging_user: false,
      };

    case types.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        getting_profile: true,
      };
    case types.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        getting_profile: false,
        profile: action.payload,
      };
    case types.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        getting_profile: false,
      };

    case types.GET_USERS_REQUEST:
      return {
        ...state,
        getting_users: true,
      };

    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        getting_users: false,
        users: action.payload,
      };
    case types.GET_USERS_FAILURE:
      return {
        ...state,
        getting_users: false,
      };

    case types.GET_USERS_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_users: true,
      };

    case types.GET_USERS_BY_FILTER_SUCCESS:
      return {
        ...state,
        getting_users: false,
        users: action.payload,
      };
    case types.GET_USERS_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_users: false,
      };

    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        creating_user: true,
        errors: []
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        creating_user: false,
        errors: []
      };
    case types.CREATE_USER_FAILURE:
      return {
        ...state,
        creating_user: false,
        errors: action.payload
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        updating_user: true,
        errors: []
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating_user: false,
        profile: action.payload,
        errors: []
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        updating_user: false,
        errors: action.payload
      };

    case types.SET_CURRENT_PATIENT:
      return {
        ...state,
        current_patient: {...action.payload}
      }

    case types.SET_CURRENT_INSURER:
    return {
      ...state,
      current_insurer: {...action.payload}
    }
    default:
      return state;
  }
}

export default UsersReducer;
