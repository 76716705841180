/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DashboardWrapper from "../Layout/DashboardWrapper";
import { connect, useDispatch } from "react-redux"
import { getUserProfile, updateUser } from "../../redux/users/actions";
import { getUserData } from "../../utils/storageUtils";
import Alert from "../Others/Alert";
import AvatarMui from "../material/Avatar";

const Profile = ({ profile, getting_profile, updating_user, errors }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    employeeWorkScheduleRequests: 
    Array.isArray(profile?.employeeWorkScheduleRequests) ? [...profile?.employeeWorkScheduleRequests]: [{
      id: 0,
      dayOfWeek: "FRIDAY",
      timeEnd: "",
      timeStart: ""
    }],
    ...profile,
  });

  const userData = getUserData()
  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form: ", form);
    dispatch(updateUser(form));
  };

  useEffect(() => {
    dispatch(getUserProfile())
    setForm({...userData.user})
  }, [])

  const program = {
    id: 0,
    dayOfWeek: "",
    timeEnd: "",
    timeStart: ""
  };

  const handleArrayChange = (e, { id, data, name }) => {
    let temp = data;
    const find = data.find((i) => i.id === id);
    const index = data.indexOf(find);
    temp[index] = {
      ...data[index],
      [e.target.name]: e.target.value,
    };
    setForm({
      ...form,
      [name]: temp,
    });
  };

  const generateId = (data) => {
    if (data.length === 0) {
      return 0;
    } else {
      return Number(data[data.length - 1].id) + 1;
    }
  };

  const handleAddProgram = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      employeeWorkScheduleRequests: [
        ...form?.employeeWorkScheduleRequests,
        { ...program, id: generateId(form.employeeWorkScheduleRequests) },
      ],
    });
  };

  const handleRemoveProgram = (e, idx) => {
    e.preventDefault();
    let tempProgram = form?.employeeWorkScheduleRequests.filter((i) => i.id !== idx);
    setForm({
      ...form,
      employeeWorkScheduleRequests: tempProgram,
    });
  };


  return (
    <>
      <DashboardWrapper showSpinner={(getting_profile || updating_user)}>
          <div className="px-4 md:px-10 py-28">
            <div className="space-y-8">
              <h3 className="text-lg sm:text-xl text-slate-700 font-medium mb-8">
                Paramètres du compte
              </h3>
              <form className="">
                <div className="space-y-12">
                  <div className="space-y-7">
                    <div className="">
                      <div className="border mb-4"></div>
                      <div>
                          {errors.length > 0 && (
                            errors.map((error, idx) => <div style={{ marginBottom: 2 }}>
                              <Alert key={idx} message={error.message}/>
                            </div>)
                          )}
                        </div>
                      <div className="mt-7 grid grid-cols-2 xl:grid-cols-5 gap-4">
                        <div className="col-span-2 space-y-3">
                          <h4 className="text-slate-700 font-medium">Basics</h4>
                          <p className="text-slate-500 text-xs leading-6">
                            Avoir une adresse e-mail à jour attachée à votre
                            compte est un grand pas vers l'amélioration de la
                            sécurité du compte.
                          </p>
                        </div>
                        <div className="col-span-3 space-y-5 sm:space-y-7">
                          <label className="relative block">
                            <span className="block text-sm font-medium text-slate-500">
                              Email
                            </span>
                            <span className="absolute top-[70%] right-0 pr-2 cursor-pointer text-slate-500 text-xl -translate-y-1/2">
                              <i className="bx bx-envelope"></i>
                            </span>
                            <input
                              type="text"
                              name="email"
                              value={form?.email}
                              onChange={handleChange}
                              className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                            />
                          </label>
                          {/* <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
                            <label className="relative block sm:grow sm:basis-full lc-form-group">
                              <span className="block text-sm font-medium text-slate-500">
                                Nouveau mot de passe
                              </span>
                              <span className="lc-form-pass absolute top-[70%] right-0 pr-2 cursor-pointer text-slate-500 text-xl -translate-y-1/2">
                                <i className="uil uil-eye-slash"></i>
                              </span>
                              <input
                                type="password"
                                name="password"
                                onChange={handleChange}
                                className="lc-form-field w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                              />
                            </label>
                            <label className="relative block sm:grow sm:basis-full lc-form-group">
                              <span className="block text-sm font-medium text-slate-500">
                                Confirmer nouveau mot de passe
                              </span>
                              <span className="lc-form-pass-confirm absolute top-[70%] right-0 pr-2 cursor-pointer text-slate-500 text-xl -translate-y-1/2">
                                <i className="uil uil-eye-slash"></i>
                              </span>
                              <input
                                type="password"
                                name="password2"
                                onChange={handleChange}
                                className="lc-form-field w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                              />
                            </label>
                          </div> */}
                          <label className="block">
                            <span className="block text-sm font-medium text-slate-500">
                              Adresse
                            </span>
                            <input
                              type="text"
                              name="address"
                              value={form?.address}
                              onChange={handleChange}
                              className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                            />
                          </label>
                          <label className="block">
                            <span className="block text-sm font-medium text-slate-500">
                              Tél
                            </span>
                            <input
                              type="text"
                              name="phoneNumber"
                              value={form?.phoneNumber}
                              onChange={handleChange}
                              className="w-full text-slate-500 py-2 pl-3 pr-3 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="border mb-4"></div>
                      <div className="mt-7 grid grid-cols-2 xl:grid-cols-5 gap-4">
                        <div className="col-span-2 space-y-3">
                          <h4 className="text-slate-700 font-medium">
                            Profile
                          </h4>
                          <p className="text-slate-500 text-xs leading-6">
                            Ces informations seront affichées publiquement,
                            alors faites attention aux informations que vous
                            fournissez.
                          </p>
                        </div>
                        <div className="col-span-3 space-y-5 sm:space-y-7">
                          <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
                            <label className="block w-auto sm:grow sm:basis-full">
                              <span className="block text-sm font-medium text-slate-500">
                                Prénom
                              </span>
                              <input
                                type="text"
                                name="firstName"
                                value={form?.firstName}
                                onChange={handleChange}
                                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                              />
                            </label>
                            <label className="block w-auto sm:grow sm:basis-full">
                              <span className="block text-sm font-medium text-slate-500">
                                Nom
                              </span>
                              <input
                                type="text"
                                name="lastName"
                                value={form?.lastName}
                                onChange={handleChange}
                                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                              />
                            </label>
                          </div>
                          <label className="block">
                            <span className="block text-sm font-medium text-slate-500">
                              Nom d'utilisateur
                            </span>
                            <input
                              type="text"
                              name="userName"
                              value={form?.userName}
                              onChange={handleChange}
                              className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                            />
                          </label>
                          <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
                            <label className="block sm:grow sm:basis-full">
                              <span className="block text-sm font-medium text-slate-500">
                                Age
                              </span>
                              <input
                                type="number"
                                name="age"
                                value={form?.age}
                                onChange={handleChange}
                                className="w-full text-slate-500 py-2 pl-3 pr-3 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                              />
                            </label>
                            <label className="block sm:grow sm:basis-full">
                              <span className="block text-sm font-medium text-slate-500">
                                Sexe
                              </span>
                              <select value={form?.sex} onChange={handleChange} name="sex" className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                                <option value="M">M</option>
                                <option value="F">F</option>
                              </select>
                            </label>
                          </div>
                          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 md:space-x-9">
                            <div className="shrink-0">
                              {/* <img
                                className="h-20 w-20 sm:h-24 sm:w-24 object-cover rounded-full ring-2 ring-offset-4 ring-gray-200 hover:ring-green-200 transition-all ease-in-out duration-300"
                                src="./images/profil-2.png"
                                alt="Current profile photo"
                              /> */}
                              <AvatarMui sx={{ height: "7rem", width: "7rem"}} text={form?.firstName + " " + form?.lastName} />
                            </div>
                            <label className="block">
                              <span className="sr-only">
                                Choose profile photo
                              </span>
                              <input
                                type="file"
                                className="block w-full text-xs sm:text-sm text-slate-500 file:text-xs sm:file:text-sm file:font-semibold file:py-2 file:px-4 file:bg-green-50 file:text-green-700 file:rounded-full file:border-0 file:mr-4 hover:file:bg-green-100"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block space-y-5">
                    <h3 className="text-lg sm:text-xl text-slate-700 font-medium">
                      Disponibilité
                    </h3>
                    <div className="block w-full overflow-x-auto rounded-md">
                      <table className="w-full">
                        <tbody className="lc-available-tbody">
                          <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                            <td className="px-3 py-3">Date</td>
                            <td className="px-3 py-3">Heure/Début</td>
                            <td className="px-3 py-3">Heure/Fin</td>
                            <td className="px-3 py-3"></td>
                          </tr>
                          {form?.employeeWorkScheduleRequests?.map((program, idx) => (
                            <tr key={idx} className="lc-available-row">
                            <td className="p-3 border-b border-b-gray-100">
                              <select className="w-full text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                                <option value="Lundi">Lundi</option>
                                <option value="Mardi">Mardi</option>
                                <option value="Mercredi">Mercredi</option>
                                <option value="Jeudi">Jeudi</option>
                                <option value="Vendredi">Vendredi</option>
                                <option value="Samedi">Samedi</option>
                              </select>
                            </td>
                            <td className="p-3 border-b border-b-gray-100">
                              <input
                                type="time"
                                className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                              />
                            </td>
                            <td className="p-3 border-b border-b-gray-100">
                              <input
                                type="time"
                                className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                              />
                            </td>
                            <td className="p-3 border-b border-b-gray-100">
                              <div className="flex items-center justify-center space-x-1">
                                <button
                                  type="button"
                                  className="lc-available-delete flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-end">
                      <button
                        id="addAvailable"
                        type="button"
                        onClick={(e) => handleAddProgram(e)}
                        className="flex items-center text-blue-500 hover:bg-blue-100 pr-4 hover:pl-4 py-2 rounded-md disabled:opacity-75 transition-all ease-in-out duration-300"
                      >
                        <i className="bx bxs-plus-circle pr-2"></i>
                        <span className="text-sm">Ajouter</span>
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-green-500 hover:bg-green-600 shadow-md text-white text-sm mt-12 font-medium px-6 py-2.5 rounded-md transition-all duration-300"
                >
                  Enrégistrer
                </button>
              </form>
            </div>
          </div>
      </DashboardWrapper>
    </>
  );
};
const mapStateToProps = ({ usersReducer }) => ({
  profile: usersReducer.profile,
  getting_profile: usersReducer.getting_profile,
  updating_user: usersReducer.updating_user,
  errors: usersReducer.errors
})

export default connect(mapStateToProps)(Profile);
