import React from "react";
import ModalUI from "../material/Modal";
import AvatarMui from "../material/Avatar";
import { roles } from "../../constants/roles";

const ShowUser = ({ data, isOpen, onClose }) => {
  return (
    <ModalUI
      isOpen={isOpen}
      styles={{
        top: 0,
        left: "unset",
        transform: "unset",
        right: 0,
        height: "100%",
        width: "25%",
      }}
      onClose={onClose}
    >
      <div>
        <span className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300">
          <i className="bx bx-x"></i>
        </span>
        <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
          <div className="relative">
            <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-4 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">
              Profile
            </h4>
            <span className="flex items-center mb-8">
              <span className="block h-[5px] w-[5px] bg-green-500 mr-1 rounded-full"></span>
              <span className="text-sm text-slate-500 font-medium">
                {data?.roleName === roles.ROLE_DOCTOR ? "Médécin": data?.roleName === roles.ROLE_SECRETARY? "Secrétaire": data?.roleName === roles.ROLE_ADMIN ? "Administrateur": ""}
              </span>
            </span>
            <div className="flex flex-col items-center justify-center text-center">
              <div className="relative">
                <AvatarMui text={data?.firstName + " " + data?.lastName} />
                <br/>
                <span className="absolute -bottom-2.5 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-emerald-100 text-emerald-500 text-xs font-semibold rounded-xl">
                  Actif
                </span>
              </div>
              <h3 className="mt-6 text-slate-900 text-lg font-semibold">
                {data?.firstName} {data?.lastName}
              </h3>
              <span className="text-xs text-slate-400">
                <i className="uil uil-location-point text-pink-500 pr-1"></i>{" "}
                {data?.address}
              </span>
              <span className="text-slate-500 text-sm mt-6">
                {data?.email} | {data?.phoneNumber}
              </span>
            </div>
          </div>
          {data?.roleName !== roles.ROLE_PATIENT && (
            <div className="relative">
              <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-8 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">
                Jour de travail
              </h4>
              <div className="space-y-5">
                <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                  Lundi : 07h - 18h
                </div>
                <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                  Jeudi : 18h - 00h
                </div>
                <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                  vendredi : 18h - 00h
                </div>
              </div>
            </div>
          )}
          {data?.roleName === roles.ROLE_PATIENT && (
            <>
              <div className="w-full space-y-2 mt-6 text-slate-500">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">Sexe:</span>
                  <span className="">
                    {data?.sex === "M" ? "Masculin" : "Feminin"}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">Assurance:</span>
                  <span className="text-blue-500">
                    {data?.insurer?.name}
                  </span>
                </div>
              </div>
              <div className="w-full text-left mt-6">
                <a
                  href="./images/profil-1.png"
                  className="text-blue-500 text-sm hover:underline transition-all ease-in-out duration-300"
                >
                  Voir la fiche de consentement patient.
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalUI>
  );
};

export default ShowUser;
