import { combineReducers } from "redux";
import usersReducer from '../users/reducer.js'
import drugsReducer from "../drugs/reducer.js";
import actsReducer from "../acts/reducer.js";
import diseasesReducer from "../diseases/reducer.js";
import consultationsReducer from "../consultations/reducer.js";
import invoicesReducer from "../invoice/reducer.js";
import uploadsReducer from "../uploads/reducer.js";

const reducerCombination = combineReducers({
    usersReducer,
    drugsReducer,
    actsReducer,
    diseasesReducer,
    consultationsReducer,
    invoicesReducer,
    uploadsReducer
});

export default reducerCombination;