import * as types from "./types"

export const createDrug = (payload) => ({
  type: types.CREATE_DRUG_REQUEST,
  payload
});

export const createStockDrug = (payload) => ({
  type: types.CREATE_DRUG_STOCK_REQUEST,
  payload
})
export const getDrugsByFilter = (payload) => ({
  type: types.GET_DRUGS_BY_FILTER_REQUEST,
  payload
})

export const getDrugsStockByFilter = (payload) => ({
  type: types.GET_DRUGS_STOCK_BY_FILTER_REQUEST,
  payload
})


export const updateDrug = (payload) => ({
  type: types.UPDATE_DRUG_REQUEST,
  payload
});

export const deleteDrug = (payload) => ({
  type: types.DELETE_DRUG_REQUEST,
  payload
});