export const getToken = () => localStorage.getItem("Bearer");

export const setToken = (token) => localStorage.setItem("Bearer", token);

export const removeStorageItem = (key) => {
  if (localStorage.getItem(key)) {
    return localStorage.removeItem(key);
  }
  return;
};

export const getUserData = () => {
  if (
    localStorage.getItem("userdata") === null ||
    localStorage.getItem("userdata") === undefined
  ) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem("userdata"));
  }
};

export const setStoragePatient = (patient) =>
  localStorage.setItem("patient", JSON.stringify(patient));

export const setStorageInsurer = (insurer) =>
  localStorage.setItem("insurer", JSON.stringify(insurer));

export const getStoragePatient = () => {
  if (
    localStorage.getItem("patient") === null ||
    localStorage.getItem("patient") === undefined
  ) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem("patient"));
  }
};

export const getStorageInsurer = () => {
  if (
    localStorage.getItem("insurer") === null ||
    localStorage.getItem("insurer") === undefined
  ) {
    return null;
  } else {
    return JSON.parse(localStorage.getItem("insurer"));
  }
};
