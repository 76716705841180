/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../Layout/DashboardWrapper'
import { connect, useDispatch } from 'react-redux';
import { createUser, getUsersByFilter, setCurrentPatient } from '../../redux/users/actions';
import { roles } from '../../constants/roles';
import { useNavigate } from 'react-router-dom';
import { setStoragePatient } from '../../utils/storageUtils';
import Alert from '../Others/Alert';
import ModalUI from '../material/Modal';
import AvatarMui from '../material/Avatar';
import img from "../../assets/fiche_placeholder.jpeg"
import { uploadFile } from '../../redux/uploads/actions';
import Select from "react-select";
import { Autocomplete, TextField } from "@mui/material";
import Pagination from '../Others/Pagination';

const Patient = ({ users, insurers, getting_users, creating_user, errors, uploadLink }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState({
    roleName: roles.ROLE_PATIENT,
    insurerId: ""
  })
  const [search, setSearch] = useState("")

  const handleChange = (e) => setForm(form => ({
    ...form,
    [e.target.name]: e.target.value
  }))

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("FORM: ", form)
    dispatch(createUser({
      ...form,
      consent: uploadLink
    }))
    setOpenFormModal(false)
  }

  const handleSetPatient = (patient) => {
    dispatch(setCurrentPatient(patient))
    setStoragePatient(patient)
    navigate("/consultation")
    console.log("sdfdsfs: ", patient)
  }

  useEffect(() => {
    dispatch(getUsersByFilter({}))
  }, []);

  const [isOpen, setIsOpen] = useState(false)
  const [localPatient, setLocalPatient] = useState()
  const [openFormModal, setOpenFormModal] = useState(false)

  const [imageError, setImageError] = useState("")

  const adaptDoctorsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.userName || item?.firstName + " " + item?.lastName
    }));

    return temp;
  };

  const [data, setData] = useState(users)

  useEffect(() => {
    setData(users)
  }, [users])

  return (
    <>
      <DashboardWrapper showSpinner={(getting_users || creating_user)}>
        <ModalUI
          isOpen={isOpen}
          styles={{ top: 0, left: "unset", transform: "unset", right: 0, height: "100%", width: "25%" }}
          onClose={() => setIsOpen(false)}
        >
          <div>
                <span onClick={() => setIsOpen(false)} className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300" data-lc-target="#profile-1"><i className='bx bx-x'></i></span>
                <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
                  <div className="relative">
                    <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-4 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">Profile</h4>
                    <span className="flex items-center mb-8">
                      <span className="block h-[5px] w-[5px] bg-pink-500 mr-1 rounded-full"></span>
                      <span className="text-sm text-slate-500 font-medium">Patient</span>
                    </span>
                    <div className="flex flex-col items-center justify-center text-center">
                      <div className="relative">
                        {/* <img className="h-w-32 w-32 ring-2 ring-offset-2 ring-gray-200 rounded-full" src="./images/avatar-placeholder.png" alt="avatar placeholder" /> */}
                        <AvatarMui text={localPatient?.firstName + " " + localPatient?.lastName} />
                      </div>
                      <span className="mt-6 mb-3 text-xs text-slate-500 uppercase">{localPatient?.occupation}</span>
                      <h3 className="text-slate-900 text-lg font-semibold">{localPatient?.firstName} {localPatient?.lastName} ~ {localPatient?.age && <span className="text-sm">{localPatient?.age} ans</span>}</h3>
                      <span className="text-xs text-slate-400"><i className="uil uil-location-point text-pink-500 pr-1"></i>{localPatient?.address}</span>
                      <span className="text-slate-500 text-sm mt-6">{localPatient?.email} | {localPatient?.phoneNumber}</span>
                      <div className="w-full space-y-2 mt-6 text-slate-500">
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Sexe:</span>
                          <span className="">{localPatient?.sex === "M" ? "Masculin": "Feminin"}</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Assurance:</span>
                          <span className="text-blue-500">{localPatient?.insurer?.name}</span>
                        </div>
                      </div>
                      <div className="w-full text-left mt-6">
                        <a href="./images/profil-1.png" className="text-blue-500 text-sm hover:underline transition-all ease-in-out duration-300">Voir la fiche de consentement patient.</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </ModalUI>
          <div className="px-4 md:px-10 py-28">
            <div className="space-y-8">
              <h3 className="text-lg sm:text-2xl text-slate-700 font-semibold">Liste des patients</h3>
              <button onClick={() => setOpenFormModal(true)} className="lc-modal-show flex items-center text-green-500 hover:bg-green-100 pr-4 hover:pl-4 py-2 rounded-md transition-all ease-in-out duration-300"><i className='bx bxs-plus-circle pr-2' ></i><span className="text-sm">Ajouter patient</span></button>
              <div className="flex flex-wrap md:flex-nowrap items-center justify-between">
                <label className="relative w-full md:w-96">
                  <span className="absolute left-2 top-[56%] -translate-y-2/4 text-slate-500 text-xl"><i className='bx bx-search' ></i></span>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="w-full text-sm sm:text-base text-slate-500 pl-8 pr-6 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md" placeholder="Recherche" />
                </label>
                <div className="w-full md:w-52 mt-4 md:mt-0 flex justify-end">
                  <button className="lc-filter flex items-center text-gray-500 hover:bg-gray-200 border px-[15px] h-10 py-1.5 rounded-md transition-all ease-in-out duration-300"><i className='bx bx-filter text-lg pr-1' ></i><span className="text-base">Filter</span></button>
                </div>
              </div>
              <div className="hidden lc-filter-container">
                <div className="block md:flex md:items-center md:justify-between md:space-x-4 space-y-4 md:space-y-0">
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 1</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 2</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 3</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 4</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="block w-full overflow-x-auto rounded-md">
                <table className="w-full mt-6">
                  <tbody>
                    <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                      <td className="px-3 py-3">Prénom</td>
                      <td className="px-3 py-3">Nom</td>
                      <td className="px-3 py-3">Profession</td>
                      <td className="px-3 py-3">Assurance</td>
                      <td className="px-3 py-3 text-right">Age</td>
                      <td className="px-3 py-3">Tél</td>
                      <td className="px-3 py-3">Adresse</td>
                      <td className="px-3 py-3"></td>
                    </tr>
                    {data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(user => user.roleName === roles.ROLE_PATIENT).filter(user => user?.firstName.toLowerCase().includes(search.toLocaleLowerCase()) || user?.lastName.toLowerCase().includes(search.toLocaleLowerCase())).map((user, idx) => (
                      <tr key={idx} className="group/table text-xs sm:text-sm text-slate-500 font-medium hover:bg-blue-50 transition-all ease-in-out duration-300">
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{user?.firstName}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{user?.lastName}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{user?.occupation}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-center">NSIA</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right">{user?.age}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{user?.phoneNumber}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{user?.address}</td>
                        <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                          <div className="flex items-center space-x-1">
                            <button onClick={(e) => {
                              e.preventDefault();
                              handleSetPatient(user)
                            }} className="lc-modal-show flex items-center justify-center h-8 w-8 hover:bg-gray-200 hover:text-slate-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className='bx bx-list-plus'></i></button>
                            <button onClick={() => {
                              setLocalPatient(user)
                              setIsOpen(true)
                            }} className="lc-modal-show flex items-center justify-center h-8 w-8 hover:bg-gray-200 hover:text-slate-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className='uil uil-eye' ></i></button>
                            {/* <button className="flex items-center justify-center h-8 w-8 hover:bg-amber-100 hover:text-amber-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className='bx bx-edit-alt' ></i></button> */}
                            <button className="flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className='bx bx-trash' ></i></button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="lc-modal fixed right-0 top-0 h-full w-full sm:w-[400px] bg-gray-50 m-0-important p-5 opacity-0 pointer-events-none z-[999999] transition-all ease-in-out duration-300" data-lc-target="profile-1">
                <span className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300" data-lc-target="#profile-1"><i className='bx bx-x'></i></span>
                <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
                  <div className="relative">
                    <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-4 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">Profile</h4>
                    <span className="flex items-center mb-8">
                      <span className="block h-[5px] w-[5px] bg-pink-500 mr-1 rounded-full"></span>
                      <span className="text-sm text-slate-500 font-medium">Patient</span>
                    </span>
                    <div className="flex flex-col items-center justify-center text-center">
                      <div className="relative">
                        <img className="h-w-32 w-32 ring-2 ring-offset-2 ring-gray-200 rounded-full" src="./images/avatar-placeholder.png" alt="avatar placeholder" />
                      </div>
                      <span className="mt-6 mb-3 text-xs text-slate-500 uppercase">Developer</span>
                      <h3 className="text-slate-900 text-lg font-semibold">Winboy TheMenace ~ <span className="text-sm">22 ans</span></h3>
                      <span className="text-xs text-slate-400"><i className="uil uil-location-point text-pink-500 pr-1"></i> Cotonou, Littoral</span>
                      <span className="text-slate-500 text-sm mt-6">winboy@gmail.com | (+229) 98 39 38 02</span>
                      <div className="w-full space-y-2 mt-6 text-slate-500">
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Sexe:</span>
                          <span className="">Masculin</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Assurance:</span>
                          <span className="text-blue-500">NSIA</span>
                        </div>
                      </div>
                      <div className="w-full text-left mt-6">
                        <a href="./images/profil-1.png" className="text-blue-500 text-sm hover:underline transition-all ease-in-out duration-300">Voir la fiche de consentement patient.</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 m-0-important opacity-0 pointer-events-none z-[99999] transition-all ease-in-out duration-300" data-lc-target="profile-1"></div>
              <div className="lc-modal fixed left-1/2 top-1/2 h-full sm:h-auto w-full sm:max-w-xl bg-white m-0-important opacity-0 pointer-events-none z-[999999] -translate-x-1/2 -translate-y-1/2 rounded-none sm:rounded-md transition-all ease-in-out duration-300" data-lc-target="newPatient">
                <span className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300" data-lc-target="#newPatient"><i className='bx bx-x'></i></span>
                <ModalUI
                  isOpen={openFormModal}
                  onClose={() => setOpenFormModal(false)}
                  styles={{height: "80%"}}
                >
                <form className="h-full py-5">
                  <h2 className="text-lg text-slate-700 font-medium text-center mb-8">Enregistrer un patient</h2>
                  <div className="pb-5 px-5 h-full max-h-[calc(100%-10%)] sm:max-h-[500px] overflow-auto">
                  <div>
                    {errors.length > 0 && (
                      errors.map((error, idx) => <div style={{ marginBottom: 2 }}>
                        <Alert key={idx} message={error.message}/>
                      </div>)
                    )}
                  </div>
                    <div className="space-y-4 sm:space-y-6">
                      <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block w-auto sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Prénom</span>
                          <input type="text" name="firstName" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block w-auto sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Nom</span>
                          <input type="text" name="lastName" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                      </div>
                      <label className="relative block">
                        <span className="block text-sm font-medium text-slate-500">Email</span>
                        <span className="absolute top-[70%] right-0 pr-2 text-slate-500 text-xl -translate-y-1/2"><i className='bx bx-envelope' ></i></span>
                        <input type="email" name="email" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                      </label>
                      <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Age</span>
                          <input type="number" name="age" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Sexe</span>
                          {/* <select name="sex" onChange={handleChange} className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                            <option value="M">M</option>
                            <option value="F">F</option>
                          </select> */}
                          <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          clearText={<></>}
                          clearIcon={<></>}
                          options={[{ label: "M", value: "M" }, { label: "F", value: "F"}]}
                          sx={{ height: 20 }}
                          renderInput={(params) => <TextField sx={{ height: 15}} {...params} />}
                          onChange={(e, val) => {
                            setForm({
                              ...form,
                              sex: val.value
                            })
                          }}
                        />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Doctor</span>
                          {/* <select name="doctorId" onChange={handleChange} className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                            {users.filter(user => user.roleName === roles.ROLE_DOCTOR)
                                  .map((user, idx) => (<option key={idx} value={user.id}>{user.userName}</option>))}
                          </select> */}
                          <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          clearText={<></>}
                          clearIcon={<></>}
                          options={adaptDoctorsOptions(users.filter(user => user.roleName === roles.ROLE_DOCTOR))}
                          sx={{ height: 20 }}
                          renderInput={(params) => <TextField sx={{ height: 15}} {...params} />}
                          onChange={(e, val) => {
                            setForm({
                              ...form,
                              doctorId: val.value
                            })
                          }}
                        />
                        </label>
                      </div>
                      <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Profession</span>
                          <input name="occupation" onChange={handleChange} type="text" className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Type d'assurance</span>
                          <select name="insurerId" onChange={handleChange} className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                            <option value="">Assureur du patient</option>
                            {users.filter(user => user.roleName === roles.ROLE_INSURER)
                              .map((user, idx) => <option key={idx} value={user?.id}>{user?.name}</option>)}
                            {/* <option value="NSIA">NSIA</option>
                            <option value="UBA">UBA</option> */}
                          </select>
                        </label>
                      </div>
                      {form?.insurerId.length > 0 && <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Pourcentage sur examen du patient</span>
                          <input name="examPercent" onChange={handleChange} type="number" className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Pourcentage sur consultation du patient</span>
                          <input type="number" name="consultationPercent" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                      </div>}
                      <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Adresse</span>
                          <input name="address" onChange={handleChange} type="text" className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Tél</span>
                          <input type="text" name="phoneNumber" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                      </div>
                      <div className="space-y-4">
                      {imageError.length > 0 && <Alert message={imageError} />}
                        <span className="block text-sm font-medium text-slate-500">Fiche de consentement</span>
                        <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 md:space-x-9">
                          <div className="shrink-0">
                            <img className="h-20 w-20 sm:h-24 sm:w-24 object-cover rounded-full ring-2 ring-offset-4 ring-gray-200 hover:ring-green-200 transition-all ease-in-out duration-300" src={img} alt="Fiche consentement" />
                          </div>
                          <label className="block">
                            <span className="sr-only">Choose profile photo</span>
                            <input type="file" className="block w-full text-xs sm:text-sm text-slate-500 file:text-xs sm:file:text-sm file:font-semibold file:py-2 file:px-4 file:bg-green-50 file:text-green-700 file:rounded-full file:border-0 file:mr-4 hover:file:bg-green-100"
                              onChange={(e) => {
                                var imagedata = document.querySelector('input[type="file"]').files[0];
                                console.log("E: ", imagedata)
                                if(imagedata){
                                  if(imagedata.size > 5 * 1024 * 1024) {
                                    setImageError("La taille du fichier ne doit pas depasser 5mo")
                                  }
                                  if(!imagedata.type.match(/\.(jpg|jpeg|png|pdf)$/)) {
                                    setImageError("Fichier non supporté")
                                  }
                                  setImageError("")
                                  dispatch(uploadFile(imagedata))
                                }
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <button onClick={handleSubmit} className="w-full bg-blue-500 hover:bg-blue-600 shadow-md text-white text-base sm:text-lg font-medium py-2 sm:py-2.5 rounded-md transition-all duration-300">Enregistrer</button>
                    </div>
                  </div>
                </form>
                </ModalUI>
              </div>
              <div className="lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 m-0-important opacity-0 pointer-events-none z-[99999] transition-all ease-in-out duration-300" data-lc-target="newPatient"></div>
              <Pagination
              data={users
                .filter(
                  (user) =>
                    user.roleName === roles.ROLE_PATIENT
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .filter(
                  (user) =>
                    user?.firstName
                      .toLowerCase()
                      .includes(search.toLocaleLowerCase()) ||
                    user?.lastName
                      .toLowerCase()
                      .includes(search.toLocaleLowerCase())
                )}
              setData={setData}
            />
            </div>
          </div>
      </DashboardWrapper>
    </>
  )
}

const mapStateToProps = ({ usersReducer, uploadsReducer }) => ({
  users: usersReducer.users,
  insurers: usersReducer.insurers,
  getting_users: usersReducer.getting_users,
  creating_user: usersReducer.creating_user,
  errors: usersReducer.errors,
  uploadLink: uploadsReducer.link_consentement

})

export default connect(mapStateToProps)(Patient)