import React, { useEffect, useState } from "react";
import ModalUI from "../material/Modal";
import Alert from "../Others/Alert";
import { Autocomplete, TextField } from "@mui/material";

const EditAssurerDetail = ({
  isOpen,
  onClose,
  data = {},
  acts = [],
  current_insurer,
  handleSubmit,
  errors,
}) => {
  const [form, setForm] = useState({
    ...data,
    insurerId: current_insurer?.id,
    actsId: data?.acts?.id || ""
  });

  useEffect(() => {
    setForm((form) => ({
      ...data,
      ...form,
      actsId: data?.acts?.id || ""
    }));
  }, []);
  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleUpdate = (e) => {
    e.preventDefault();
    handleSubmit(form);
  };

  const adaptSelectActsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.label
    }));

    return temp;
  };

  return (
    <ModalUI isOpen={isOpen} onClose={onClose}>
      <form className="h-full py-5">
        <h2 className="text-lg text-slate-700 font-medium text-center mb-8">
          Mettre a jour l'acte de l'assureur
        </h2>
        <div className="pb-5 px-5 h-full max-h-[calc(100%-10%)] sm:max-h-[500px] overflow-auto">
          <div>
            {errors.length > 0 &&
              errors.map((error, idx) => (
                <div key={idx} style={{ marginBottom: 2 }}>
                  <Alert message={error.message} />
                </div>
              ))}
          </div>
          <div className="space-y-4 sm:space-y-6">
            <label className="relative block">
              <span className="block text-sm font-medium text-slate-500">
                Act Name
              </span>
              {/* <select
                name="actsId"
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                <option value={data?.acts?.id}>{data?.acts?.label}</option>
                {acts.map((act, idx) => (
                  <option key={idx} value={act?.id}>
                    {act?.label}
                  </option>
                ))}
              </select> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearText={<></>}
                clearIcon={<></>}
                options={adaptSelectActsOptions(acts)}
                // className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
                renderInput={(params) => <TextField {...params} label="act" />}
                onChange={(e, val) => {
                  setForm({
                    ...form,
                    actsId: val.value
                  })
                }}
              />
            </label>
            <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
              <label className="block sm:grow sm:basis-full">
                <span className="block text-sm font-medium text-slate-500">
                  Price
                </span>
                <input
                  type="number"
                  name="price"
                  min="1"
                  onChange={handleChange}
                  value={form.price || data?.price}
                  className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
                />
              </label>
            </div>
            <button
              onClick={handleUpdate}
              className="w-full bg-blue-500 hover:bg-blue-600 shadow-md text-white text-base sm:text-lg font-medium py-2 sm:py-2.5 rounded-md transition-all duration-300"
            >
              Mettre a jour
            </button>
          </div>
        </div>
      </form>
    </ModalUI>
  );
};

export default EditAssurerDetail;
