/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DashboardWrapper from "../Layout/DashboardWrapper";
import { connect, useDispatch } from "react-redux";
import {
  fetchAllActs,
  deleteAct,
  getActsPriceInsurerFilter,
  createActsPriceInsurer,
  updateActsPriceInsurer,
  deleteActsPriceInsurer,
} from "../../redux/acts/actions";
import Alert from "../Others/Alert";
import { getStorageInsurer } from "../../utils/storageUtils";
import EditAssurerDetail from "./EditAssurerDetail";
import DialogMui from "../material/Dailog";
import { Autocomplete, TextField } from "@mui/material";

const storageInsurer = getStorageInsurer();

const AssureurDetails = ({
  acts,
  fetch_acts_status,
  create_acts_status,
  delete_act_status,
  errors,
  getting_acts_price_insurer,
  acts_price_insurer,
  current_insurer: curr_insurer,
}) => {
  const dispatch = useDispatch();
  const current_insurer = Object.keys(curr_insurer).length
    ? curr_insurer
    : storageInsurer;
  const [form, setForm] = useState({
    insurerId: current_insurer?.id,
  });

  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleSelect = (e) => {
    console.log("E.target.value: ", e.target.value);
    setForm({
      ...form,
      actsId: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("FORM: ", form);
    dispatch(createActsPriceInsurer(form));
  };

  useEffect(() => {
    dispatch(
      getActsPriceInsurerFilter({ limit: 10, insurerId: current_insurer?.id })
    );
    dispatch(fetchAllActs({ limit: 10 }));
    console.log("acts: ", acts);
  }, []);

  const handleDelete = (e, id) => {
    e.preventDefault();
    setOpenDialog(true);
  };

  console.log("current_insurer: ", current_insurer);
  const [openModal, setOpenModal] = useState(false);
  const [currentInsurerDetail, setCurrentInsurerDetail] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const adaptSelectActsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.label
    }));

    return temp;
  };

  return (
    <>
      <DashboardWrapper
        showSpinner={
          create_acts_status || fetch_acts_status || delete_act_status
        }
      >
        <div className="px-4 md:px-10 py-28">
          <EditAssurerDetail
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            data={currentInsurerDetail}
            acts={acts}
            current_insurer={current_insurer}
            errors={errors}
            handleSubmit={(data) => {
              dispatch(updateActsPriceInsurer(data));
            }}
          />
          <DialogMui
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onCancel={() => setOpenDialog(false)}
            onConfirm={() => {
              dispatch(deleteActsPriceInsurer(currentInsurerDetail));
              setOpenDialog(false)
            }}
          />
          {/* {JSON.stringify({ acts })} */}
          <div>
            {errors.length > 0 &&
              errors.map((error, idx) => (
                <div key={idx} style={{ marginBottom: 2 }}>
                  <Alert message={error.message} />
                </div>
              ))}
          </div>
          <form className="block space-y-4 lg:flex md:items-end lg:justify-between lg:space-x-4 lg:space-y-0">
            <label className="block grow basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Act Name
              </span>
              {/* <select name="actsId" onChange={handleChange}>
                {acts.map((act, idx) => (
                  <option key={idx} value={act?.id}>
                    {act?.label}
                  </option>
                ))}
              </select> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearText={<></>}
                clearIcon={<></>}
                options={adaptSelectActsOptions(acts)}
                // className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
                renderInput={(params) => <TextField {...params} label="act" />}
                onChange={(e, val) => {
                  setForm({
                    ...form,
                    actsId: val.value
                  })
                }}
              />
            </label>
            <label className="block grow basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Price
              </span>
              <input
                type="number"
                name="price"
                min="1"
                onChange={handleChange}
                value={form.basePrice}
                style={{ height: "53px" }}
                className="w-full px-3 py-2 mt-1 border-none rounded-md text-slate-500 focus:bg-transparent ring-1 focus:ring-1 ring-gray-200 focus:ring-gray-200"
              />
            </label>
            <button
              type="button"
              onClick={handleSubmit}
              style={{ height: "53px" }}
              className="block py-2 pl-4 pr-4 text-white transition-all duration-300 ease-in-out bg-green-500 rounded-md grow basis-full hover:bg-green-600"
            >
              <span className="text-sm">Ajouter</span>
            </button>
          </form>

          <div class="block w-full overflow-x-auto rounded-md">
            <table class="w-full mt-6">
              <tbody>
                <tr class="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                  <td class="px-3 py-3">Examens</td>
                  <td class="px-3 py-3 text-right">Prix</td>
                  <td class="px-3 py-3"></td>
                </tr>
                {acts_price_insurer.map((item, idx) => (
                  <tr class="group/table text-xs sm:text-sm text-slate-500 font-medium hover:bg-blue-50 transition-all ease-in-out duration-300">
                    <td class="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                      {item?.acts.label}
                    </td>
                    <td class="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right text-blue-500">
                      {item?.price}f.cfa
                    </td>
                    <td class="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                      <div class="flex items-center justify-end space-x-1">
                        <button
                          onClick={() => {
                            setOpenModal(true);
                            setCurrentInsurerDetail(item);
                          }}
                          class="flex items-center justify-center h-8 w-8 hover:bg-amber-100 hover:text-amber-500 text-lg rounded-full transition-all ease-in-out duration-300"
                        >
                          <i class="bx bx-edit-alt"></i>
                        </button>
                        <button
                          onClick={(e) => {
                            handleDelete(e, item?.id);
                            setCurrentInsurerDetail(item);
                          }}
                          class="flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = ({ actsReducer, usersReducer }) => ({
  acts: actsReducer.acts,
  fetch_acts_status: actsReducer.fetch_acts_status,
  create_acts_status: actsReducer.create_acts_status,
  delete_act_status: actsReducer.delete_act_status,
  errors: actsReducer.errors,
  getting_acts_price_insurer: actsReducer.getting_acts_price_insurer,
  acts_price_insurer: actsReducer.acts_price_insurer,
  current_insurer: usersReducer.current_insurer,
});

export default connect(mapStateToProps)(AssureurDetails);
