import config from '../../config';
import { logoutHelper } from '../../utils/logoutHelper';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

/**
 *
 * @param {*} payload
 * @returns drug details
 */
export const createDrug = async (payload, token) => {
  // myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: 'follow'
  };
  delete payload?.action

  try {
    const request = await fetch(config.API + "/api/drugs", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('CreateDrugServiceErr: ', error);
    logoutHelper()
  }
}

export const createDrugStock = async (payload, token) => {
  // myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: 'follow'
  };
  delete payload?.action

  try {
    const request = await fetch(config.API + "/api/drugs-stock", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('CreateDrugStockServiceErr: ', error);
    logoutHelper()
  }
}


export const getDrugsByFilter = async (payload, token) => {
  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer " + token);
  // myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(payload);

  var requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow'
  };
  try {

    const request = await fetch(config.API + "/api/drugs/get-filter", requestOptions)
    const response = await request.json()
    return response
  }
  catch (err) {
    console.error('GetDrugsByFilterServiceErr: ', err);
    // logoutHelper()
  }
}

export const getDrugsStockByFilter = async (payload, token) => {
  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer " + token);
  // myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(payload);

  var requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow'
  };
  try {

    const request = await fetch(config.API + "/api/drugs-stock/get-filter", requestOptions)
    const response = await request.json()
    return response
  }
  catch (err) {
    console.error('GetDrugsByFilterServiceErr: ', err);
    logoutHelper()
  }
}

export const updateDrug = async (payload, token) => {
  // myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: 'follow'
  };
  delete payload?.action

  try {
    const request = await fetch(config.API + "/api/drugs/" + payload.id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('UpdateDrugServiceErr: ', error);
  }
}

export const deleteDrug = async (payload, token) => {
  // myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/drugs/" + payload.id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('UpdateDrugServiceErr: ', error);
  }
}