import * as types from "./types"

export const createInvoice = (payload) => ({
  type: types.CREATE_INVOICE_REQUEST,
  payload
})

export const getInvoicesByFilter = (payload) => ({
  type: types.GET_INVOICE_BY_FILTER_REQUEST,
  payload
})