import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import {
  createConsultation as createConsultationService,
  getConsultationsByFilter as getConsultationsByFilterService
} from "../../services/consultations";
import { getStoragePatient, getToken } from "../../utils/storageUtils";
import * as types from "./types";
import { adaptResponseError } from "../../utils/utils";

const token = getToken();
const storagePatient = getStoragePatient()

/**
 * @description create consultation
 */
function* createConsultation({ payload }) {
  try {
    const result = yield createConsultationService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({ type: types.CREATE_CONSULTATION_SUCCESS, payload: result?.data });
      yield put({ type: types.GET_CONSULTATIONS_BY_FILTER_REQUEST, payload: { patientId: storagePatient.id}})
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.CREATE_CONSULTATION_FAILURE, payload: adaptResponseError(result) });
    }
  } catch (error) {
    console.error("CreateConsultationSagaErr: ", error);
  }
}

function* getConsultationByFilter({ payload }) {
  try {
    const result = yield getConsultationsByFilterService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({ type: types.GET_CONSULTATIONS_BY_FILTER_SUCCESS, payload: result?.data });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.GET_CONSULTATIONS_BY_FILTER_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("GetConsultationSagaErr: ", error);
  }
}

export default function* ConsultationsSaga() {
  yield takeLatest(types.CREATE_CONSULTATION_REQUEST, createConsultation);
  yield takeLatest(types.GET_CONSULTATIONS_BY_FILTER_REQUEST, getConsultationByFilter);
}
