import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import {
  getDiseasesByFilter as getDiseasesByFilterService
} from "../../services/diseases";
import { getToken } from "../../utils/storageUtils";
import * as types from "./types";

const token = getToken();

function* getDiseasesByFilter({ payload }) {
  try {
    const result = yield getDiseasesByFilterService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({
        type: types.GET_DISEASES_BY_FILTER_SUCCESS,
        payload: result?.data || [],
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.GET_DISEASES_BY_FILTER_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("GetDiseasesByFilterSagaErr: ", error);
  }
}

export default function* DiseasesSaga() {
  yield takeLatest(types.GET_DISEASES_BY_FILTER_REQUEST, getDiseasesByFilter);
}
