import * as types from './types';

const INITIAL_STATE = {
  getting_invoices: false,
  creating_invoice: false,
  invoices: [],
  errors: []
};

function InvoicesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        creating_invoice: true
      };

    case types.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        creating_invoice: false,
      };
    case types.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        creating_invoice: false
      };

    case types.GET_INVOICE_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_invoices: true
      };

    case types.GET_INVOICE_BY_FILTER_SUCCESS:
      return {
        ...state,
        getting_invoices: false,
        invoices: action.payload
      };
    case types.GET_INVOICE_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_invoices: false
      };

    default:
      return state;
  }
}

export default InvoicesReducer;