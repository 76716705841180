import * as types from "./types";

const INITIAL_STATE = {
  act: {
    actsTypeName: "",
    basePrice: 0,
    description: "",
    label: "",
  },
  acts: [],
  fetch_acts_status: false,
  create_acts_status: false,
  error: null,
  delete_act_status: false,
  errors: [],
  acts_price_insurer: [],
  getting_acts_price_insurer: false,
  creating_acts_price_insurer: false,
  updating_acts_price_insurer: false,
  deleting_acts_price_insurer: false,
};

export default function ActsReducers(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.CREATE_ACTS_REQUEST: {
      return {
        ...state,
        create_acts_status: true,
        error: null,
        errors: [],
      };
    }
    case types.CREATE_ACTS_SUCCESS:
      return {
        ...state,
        create_acts_status: false,
        error: null,
        errors: [],
      };
    case types.CREATE_ACTS_FAILURE: {
      return {
        ...state,
        create_acts_status: false,
        error: payload,
        errors: payload,
      };
    }

    case types.FETCH_ACTS_REQUEST: {
      return {
        ...state,
        fetch_acts_status: true,
        error: null,
      };
    }
    case types.FETCH_ACTS_SUCCESS:
      return {
        ...state,
        acts: payload,
        fetch_acts_status: false,
        error: null,
      };
    case types.FETCH_ACTS_FAILURE: {
      return {
        ...state,
        fetch_acts_status: false,
        error: payload,
      };
    }

    case types.DELETE_ACTS_REQUEST: {
      return {
        ...state,
        delete_act_status: true,
        error: null,
      };
    }
    case types.DELETE_ACTS_SUCCESS:
      return {
        ...state,
        delete_act_status: false,
        error: null,
      };
    case types.DELETE_ACTS_FAILURE: {
      return {
        ...state,
        delete_act_status: false,
        error: payload,
      };
    }

    case types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST: {
      return {
        ...state,
        getting_acts_price_insurer: true
      };
    }
    case types.GET_ACTS_PRICE_INSURER_FILTER_SUCCESS:
      return {
        ...state,
        getting_acts_price_insurer: false,
        acts_price_insurer: payload
      };
    case types.GET_ACTS_PRICE_INSURER_FILTER_FAILURE: {
      return {
        ...state,
        getting_acts_price_insurer: false
      };
    }
    case types.CREATE_ACTS_PRICE_INSURER_REQUEST: {
      return {
        ...state,
        creating_acts_price_insurer: true,
        errors: []
      };
    }

    case types.CREATE_ACTS_PRICE_INSURER_SUCCESS:
      return {
        ...state,
        creating_acts_price_insurer: false,
        errors: []
      };

    case types.CREATE_ACTS_PRICE_INSURER_FAILURE: {
      return {
        ...state,
        creating_acts_price_insurer: false,
        errors: payload
      };
    }

    case types.UPDATE_ACTS_PRICE_INSURER_REQUEST: {
      return {
        ...state,
        updating_acts_price_insurer: true,
        errors: []
      };
    }
    case types.UPDATE_ACTS_PRICE_INSURER_SUCCESS:
      return {
        ...state,
        updating_acts_price_insurer: false,
        errors: []
      };
    case types.UPDATE_ACTS_PRICE_INSURER_FAILURE: {
      return {
        ...state,
        updating_acts_price_insurer: false,
        errors: payload
      };
    }

    case types.DELETE_ACTS_PRICE_INSURER_REQUEST: {
      return {
        ...state,
        deleting_acts_price_insurer: true,
        errors: []
      };
    }
    case types.DELETE_ACTS_PRICE_INSURER_SUCCESS:
      return {
        ...state,
        deleting_acts_price_insurer: false,
        errors: []
      };
    case types.DELETE_ACTS_PRICE_INSURER_FAILURE: {
      return {
        ...state,
        deleting_acts_price_insurer: false,
        errors: payload
      };
    }
    default:
      return state;
  }
}
