export const adaptResponseError = (response) => {
  console.log("RESPONSE: ", response)
  let temp = []

  temp = response.hasOwnProperty("errors") ?  Object.keys(response?.errors).map((item, idx) => ({
    message: `${Object.keys(response?.errors)[idx]}: ${Object.values(response?.errors)[idx]}`
  })) : []

  if(response.hasOwnProperty("error")) {
    console.log("ERROR: ", response?.error)
    temp = [...temp, { message: response?.error }]
  }

  if(response.hasOwnProperty("message")) {
    console.log("MESSAGE: ", response?.message)

    temp = [...temp, { message: response?.message }]
  }
  return temp
}