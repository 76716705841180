import React from "react";
import "./styles.css";

const Spinner = () => {
  return (
    <div style={{ position: "fixed", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0, .5)", zIndex: 10000 }} className="flex justify-center items-center">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
