/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Others/Spinner";
import { useDispatch, connect } from "react-redux";
import { logoutUser } from "../../redux/users/actions";
import Logo from "../../assets/logo.svg"
import { getUserData } from "../../utils/storageUtils";
import AvatarMui from "../material/Avatar";

const DashboardWrapper = ({ profile, children, showSpinner = false }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(showSpinner)
  const userData = getUserData()

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  // useEffect(() => {
  //   setShow(showSpinner)
  // }, []);

  // useEffect(() => {
  //   setShow(showSpinner)
  //   console.log("SHOW_SPINNER_: ", showSpinner)
  //   console.log("SHOW_: ", show)
  //   if(showSpinner){
  //     setShow(show)
  //   }
  //   // setShow(showSpinner)
  //   const timer = setTimeout(() => {
  //     setShow(false)
  //     console.log("TIMER CALLED: ", show)
  //   }, 3000)
  //   clearTimeout(timer)
  // }, [])

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="tw-scroll-up-btn h-10 w-10 fixed right-6 bottom-0 z-40 flex items-center justify-center rounded-md bg-green-500 hover:bg-green-600 text-white cursor-pointer opacity-0 pointer-events-none transition-all ease-in-out duration-300">
        <i className="bx bxs-chevrons-up text-xl"></i>
      </div>
      <div className="lc-sidebar group/child peer fixed h-full w-[78px] md:w-[250px] px-[10px] py-[14px] bg-white border-r-2 z-[100] transition-[left, width] duration-300 ease-linear">
        <header className="relative">
          <div className="flex items-center">
            <span className="min-w-[60px] flex items-center justify-center rounded-md">
              <img
                src={Logo}
                alt="logo la compassion"
                className="h-10 w-10 rounded-md min-w-[60px]"
              />
            </span>
            <span className="lc-logo-text opacity-0 md:opacity-100 group-[.lc-close]/child:opacity-100 md:group-[.lc-close]/child:opacity-0 text-slate-500 text-lg font-medium whitespace-nowrap transition-all duration-300 ease-in-out">
              La Compassion
            </span>
          </div>
          <i className="bx bx-chevron-left lc-toggle rotate-180 md:rotate-0 group-[.lc-close]/child:rotate-0 md:group-[.lc-close]/child:rotate-180 absolute top-1/2 -right-6 flex items-center justify-center h-[25px] w-[25px] bg-green-500 text-white cursor-pointer text-[22px] -translate-y-1/2 rounded-full"></i>
        </header>
        <div className="h-[calc(100%-55px)] flex flex-col justify-between overflow-x-hidden overflow-y-scroll">
          <div className="mt-10">
            {/* <!-- <ul className="space-y-[10px]"> --> */}
            <ul>
              <li className="flex items-center h-12">
                <Link
                  to="#"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-home-alt flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Tableau de bord
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/patient"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-user-plus flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Patients
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/personnel-medecin"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bxs-user-detail flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Médécins
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/assureur"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-shield-quarter flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Assureur/Entrepise
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/examen"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-file flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Acts
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/pharmacy"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-plus-medical flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Pharmacie
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/users"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-group flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Utilisateurs
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="/profile"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-user flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Profile
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="#"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-bell flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Notifications
                  </span>
                </Link>
              </li>
              <li className="flex items-center h-12">
                <Link
                  to="#"
                  className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
                >
                  <i className="bx bx-cog flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                  <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                    Paramètres
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <ul className="space-y-[10px]">
            <li className="flex items-center h-12" onClick={handleLogout}>
              <Link
                to="#"
                className="group flex items-center h-full w-full bg-transparent hover:bg-green-100 rounded-md transition-all ease-in-out duration-300"
              >
                <i className="bx bx-log-out flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 group-hover:text-green-500 rounded-md transition-all ease-in-out duration-300"></i>
                <span className="text-[13px] text-slate-500 group-hover:text-green-500 font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                  Déconnexion
                </span>
              </Link>
            </li>
            <li className="relative flex items-center h-12 bg-green-100 rounded-md transition-all ease-in-out duration-300">
              <div className="h-[50px] w-[60px]">
                <i className="bx bx-moon absolute flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 rounded-md lc-moon"></i>
                <i className="bx bx-sun absolute flex items-center justify-center h-full min-w-[60px] text-xl text-slate-500 rounded-md opacity-0 lc-sun"></i>
              </div>
              <span className="lc-mode-text opacity-0 md:opacity-100 group-[.lc-close]/child:opacity-100 md:group-[.lc-close]/child:opacity-0 text-[13px] text-slate-500 group-hover:text-white font-semibold whitespace-nowrap transition-all ease-in-out duration-300">
                Dark Mode
              </span>
              <div className="absolute right-0 h-full min-w-[60px] flex items-center justify-center cursor-pointer rounded-md transition-all ease-in-out duration-300before:">
                <span className="lc-switch relative h-[22px] w-10 bg-gray-300 rounded-[25px] before:absolute before:top-1/2 before:left-[5px] before:h-[15px] before:w-[15px] before:bg-white before:rounded-full before:-translate-y-1/2 before:transition-all before:ease-in-out before:duration-300"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <section className="lc-section relative group top-0 left-[78px] md:left-[250px] peer-[.lc-close]:left-[250px] md:peer-[.lc-close]:left-[78px] min-h-screen w-[calc(100%-78px)] md:w-[calc(100%-250px)] peer-[.lc-close]:w-[calc(100%-250px)] md:peer-[.lc-close]:w-[calc(100%-78px)] bg-white transition-all ease-in-out duration-300">
          <nav className="lc-admin-head fixed left-[78px] md:left-[250px] group-[.lc-close-group]:left-[250px] md:group-[.lc-close-group]:left-[78px] flex center justify-end h-20 w-[calc(100%-78px)] md:w-[calc(100%-250px)] group-[.lc-close-group]:w-[calc(100%-250px)] md:group-[.lc-close-group]:w-[calc(100%-78px)] bg-white z-[90] px-4 md:px-10 shadow-md transition-all ease-in-out duration-300">
            <div className="flex items-center space-x-2 md:space-x-4">
              <div className="flex items-center space-x-3 md:space-x-5">
                <p className="text-xs md:text-sm font-semibold text-slate-700">{(profile?.firstName || userData?.user?.firstName) + " " + (profile?.lastName || userData?.user?.lastName)}</p>
                {/* <img className="h-6 w-6 md:h-10 md:w-10 rounded-full ring-2 ring-offset-4 ring-blue-500" src="./images/profil-1.png" alt="" /> */}
              <AvatarMui text={(profile?.firstName || userData?.user?.firstName) + " " + (profile?.lastName || userData?.user?.lastName)} />
              </div>
            </div>
          </nav>
      {children}
      </section>
    </>
  );
};

const mapStateToProps =({ usersReducer }) => ({
  profile: usersReducer.profile
})

export default connect(mapStateToProps)(DashboardWrapper);
