export const CREATE_DRUG_REQUEST = "CREATE_DRUG_REQUEST"
export const CREATE_DRUG_SUCCESS = "CREATE_DRUG_SUCCESS"
export const CREATE_DRUG_FAILURE = "CREATE_DRUG_FAILURE"

export const CREATE_DRUG_STOCK_REQUEST = "CREATE_DRUG_STOCK_REQUEST"
export const CREATE_DRUG_STOCK_SUCCESS = "CREATE_DRUG_STOCK_SUCCESS"
export const CREATE_DRUG_STOCK_FAILURE = "CREATE_DRUG_STOCK_FAILURE"


export const GET_DRUGS_BY_FILTER_REQUEST = "GET_DRUGS_BY_FILTER_REQUEST"
export const GET_DRUGS_BY_FILTER_SUCCESS = "GET_DRUGS_BY_FILTER_SUCCESS"
export const GET_DRUGS_BY_FILTER_FAILURE = "GET_DRUGS_BY_FILTER_FAILURE"

export const GET_DRUGS_STOCK_BY_FILTER_REQUEST = "GET_DRUGS_STOCK_BY_FILTER_REQUEST"
export const GET_DRUGS_STOCK_BY_FILTER_SUCCESS = "GET_DRUGS_STOCK_BY_FILTER_SUCCESS"
export const GET_DRUGS_STOCK_BY_FILTER_FAILURE = "GET_DRUGS_STOCK_BY_FILTER_FAILURE"

export const UPDATE_DRUG_REQUEST = "UPDATE_DRUG_REQUEST";
export const UPDATE_DRUG_SUCCESS = "UPDATE_DRUG_SUCCESS";
export const UPDATE_DRUG_FAILURE = "UPDATE_DRUG_FAILURE";

export const DELETE_DRUG_REQUEST = "DELETE_DRUG_REQUEST"
export const DELETE_DRUG_SUCCESS = "DELETE_DRUG_SUCCESS"
export const DELETE_DRUG_FAILURE = "DELETE_DRUG_FAILURE"