import * as types from "./types"

const INITIAL_STATE = {
  uploading: false,
  errors: [],
  link_consentement: ""
}

const UploadsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case types.UPLOAD_FILE_CONSENTEMENT_REQUEST:
    return {
      ...state,
      uploading: true
    }
    case types.UPLOAD_FILE_CONSENTEMENT_SUCCESS:
    return {
      ...state,
      uploading: false,
      link_consentement: action.payload
    }
    case types.UPLOAD_FILE_CONSENTEMENT_FAILURE:
    return {
      ...state,
      uploading: false
    }
    default:
      return state;
  }
}

export default UploadsReducer