import React, { useEffect, useState } from "react";
import AccordionCustom from "../../material/Accordion";
import { connect, useDispatch } from "react-redux";
import { createInvoice, getInvoicesByFilter } from "../../../redux/invoice/actions";
import { getStoragePatient, getUserData } from "../../../utils/storageUtils";
import Select from "react-select";
import { getActsPriceInsurerFilter } from "../../../redux/acts/actions";
import { Autocomplete, TextField } from "@mui/material";

const storagePatient = getStoragePatient();
const storageUser = getUserData()
console.log("SEUSER: ", storageUser)
const TabThree = ({
  invoices,
  current_patient: curr_patient,
  acts,
  acts_price_insurer
}) => {
  const dispatch = useDispatch()
  const [openForm, setOpenForm] = useState(false)
  const current_patient = Object.keys(curr_patient).length
  ? curr_patient
  : storagePatient;

  const [form, setForm] = useState({
    invoiceActsRequests: [
      {
        id: 0,
        actsId: 0,
        amountPaid: 0,
        insurerCoverage: 0,
        invoiceAmount: 0,
        quantity: 0,
        remainingPaid: 0,
        totalInvoice: 0
      },
    ],
    patientId: current_patient?.id,
    practitionerId: storageUser?.user?.id,
    // insurerCoverage: current_patient?.insurer?.examPercent || 0
  })

  useEffect(() => {
    setForm({
      ...form,
      practitionerId: storageUser?.user?.id,
      patientId: current_patient?.id,
    });
  }, []);

  useEffect(() => {
    dispatch(getInvoicesByFilter({ patientId: current_patient?.id }))
    dispatch(getActsPriceInsurerFilter({ "insurer.id": current_patient?.insurer?.id }))
  }, [])

  const generateId = (data) => {
    if (data.length === 0) {
      return 0;
    } else {
      return Number(data[data.length - 1].id) + 1;
    }
  };
  console.log("ACTS_PRICE_INSURER: ", acts_price_insurer)

  const handleArrayChange = (e, { id, data, name }) => {
    console.log("ACT: ", form.invoiceActsRequests)
    let temp = data;
    const find = data.find((i) => i.id === id);
    const index = data.indexOf(find);
    console.log("INDEX: ", find)
    temp[index] = {
      ...data[index],
      [e.target.name]: e.target.value,
    };
    setForm({
      ...form,
      [name]: temp,
    });
    // let tempActs = localActs;
    // let findAct = acts.find(i => String(i.id) === String(find.actsId))
    // // console.log("FIDNACT: ", findAct)
    // if(findAct) {
    //   tempActs[index] = findAct
    //   setLocalActs(tempActs)
    // }
  };

  const invoiceItem = {
    id: 0,
    actsId: 0,
    amountPaid: 0,
    insurerCoverage: 0,
    invoiceAmount: 0,
    unitPrice: 0,
    quantity: 0,
    remainingPaid: 0,
    totalInvoice: 0
  };

  const handleAddInvoiceItem = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      invoiceActsRequests: [
        ...form.invoiceActsRequests,
        { ...invoiceItem, id: generateId(form.invoiceActsRequests) },
      ],
    });
  };

  const handleRemoveInvoiceItem = (e, idx) => {
    e.preventDefault();
    console.log("FILTER_H: ", idx)
    console.log("FILTER_FORM: ", form.invoiceActsRequests)
    let tempInvoices = form.invoiceActsRequests.filter((i) => i.id !== idx);
    setForm({
      ...form,
      invoiceActsRequests: tempInvoices,
    });
  };

  const [localActs, setLocalActs] = useState([{price: 0}])

  useEffect(() => {
  console.log("localActs: ", localActs)
}, [form])

const mapReturnRequests = (data) => {
  return data.map(item => ({
    actsId: item.actsId || item?.value,
    amountPaid: item.amountPaid,
    insurerCoverage: item.insurerCoverage,
    invoiceAmount: item.invoiceAmount,
    quantity: item.quantity,
    remainingPaid: item.remainingPaid,
    totalInvoice: item.totalInvoice
  }))
}
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("Form: ", form)
    dispatch(createInvoice({...form, invoiceActsRequests: mapReturnRequests(form.invoiceActsRequests)}))
  }

  const adaptOptions = (data, index) => {
    let temp = data.map((item) => ({
      value: item?.id,
      actsId: item?.id,
      label: item?.label,
      remainingPaid: 0,
      ...item,
      // ...form.invoiceActsRequests[index]
    }));
    return temp;
  };

  const calculateTotalInvoice = (act, quantity) => {
    if(act?.actsTypeName === "EXAM"){
      return (Number(act?.price) * Number(quantity))
    } else {
      return Number(act?.price) * Number(quantity)
    }
  }

  const calculateTotal = (act, quantity) => {
    // const findAct = acts_price_insurer.find(item => item.acts.id === act.id)
    // console.log("FINDACT: ", findAct)
    if(act?.actsTypeName === "EXAM"){
      const examPercent = current_patient?.examPercent || 0
      if(examPercent === 0) {
        return act?.price * Number(quantity)
      }
      return (act?.price * Number(quantity)) + ((act?.price * Number(quantity)) * (Number(examPercent)/100))
    }else{
      const consultationPercent = current_patient?.consultationPercent || 0
      if(consultationPercent === 0) {
        return act?.price * Number(quantity)
      }
      return (act?.price * Number(quantity)) + ((act?.price * Number(quantity)) * (Number(consultationPercent)/100))

    }
  }


  const adaptSelectActsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.label,
      ...item
    }));

    return temp;
  };


  return (
    <div className="lc-tab-target md:px-5 py-4" data-tab="bons">
      <button
        type="button"
        onClick={() => setOpenForm(true)}
        className="lc-new-invoice bg-green-500 hover:bg-green-600 mb-6 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300"
      >
        Nouvelle Facture
      </button>
      <form className={`${openForm ? "": "hidden"} lc-bons-form space-y-5 mb-6`}>
        <div className="block space-y-5">
          <div className="block w-full overflow-x-auto rounded-md">
            <table className="w-full">
              <tbody className="lc-available-tbody">
                <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                  <td className="px-3 py-3">ACTS</td>
                  <td className="px-3 py-3 text-right">QTE</td>
                  <td className="px-3 py-3 text-right">P.U</td>
                  <td className="px-3 py-3 text-right">% PC</td>
                  <td className="px-3 py-3 text-right">TOTAL</td>
                  <td className="px-3 py-3 text-right">MT FACT</td>
                  <td className="px-3 py-3 text-right">MT PAYE</td>
                  {/* <td className="px-3 py-3 text-right">RESTE</td> */}
                  <td className="px-3 py-3"></td>
                </tr>
                {form.invoiceActsRequests.map((item, idx) => (
                  <tr key={idx} className="lc-bon-row">
                  <td>
                    {/* <select name="actsId"
                      onChange={(e) =>
                        handleArrayChange(e, {
                            id: item.id,
                            data: form.invoiceActsRequests,
                            name: "invoiceActsRequests",
                          })
                      }
                      className="w-[200px] text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                    >
                      <option value=""></option>
                      {acts.map((i, idx) => <option key={idx} value={i?.id}>{i.label}</option>)}
                    </select> */}
                    {/* <Select
                      options={adaptOptions(acts, idx)}
                      className="w-[200px] text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      onChange={(data) => {
                        console.log("selected: ", data)
                        const findAct = acts_price_insurer.find(item => String(item.acts.id) === String(data.value))
                        console.log("FINDACT: ", findAct)
                        let temp = form.invoiceActsRequests
                        temp[idx] = data
                        setLocalActs(temp)
                        let tempInvoices = form.invoiceActsRequests
                        console.log("INVOICEI: ", tempInvoices[idx])

                        tempInvoices[idx] = {
                          ...data,
                          ...tempInvoices[idx],
                          totalInvoice: calculateTotal(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          amountPaid: calculateTotal(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          invoiceAmount: calculateTotal(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          insurerCoverage: data?.actsTypeName === "EXAM" ? current_patient?.examPercent: current_patient?.consultationPercent || 0,
                        }
                        setForm({
                          ...form,
                          invoiceActsRequests: tempInvoices
                        })
                      }}
                     /> */}
                     <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      clearText={<></>}
                      clearIcon={<></>}
                      options={adaptSelectActsOptions(acts)}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="act" />}
                      onChange={(e, data) => {
                        console.log("selected: ", data)
                        const findAct = acts_price_insurer.find(item => String(item.acts.id) === String(data.value))
                        console.log("FINDACT: ", findAct)
                        let temp = form.invoiceActsRequests
                        temp[idx] = data
                        setLocalActs(temp)
                        let tempInvoices = form.invoiceActsRequests
                        console.log("INVOICEI: ", tempInvoices[idx])

                        tempInvoices[idx] = {
                          ...data,
                          ...tempInvoices[idx],
                          actsId: data?.value,
                          totalInvoice: calculateTotalInvoice(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          amountPaid: calculateTotal(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          invoiceAmount: calculateTotal(findAct? findAct: data, tempInvoices[idx]?.quantity),
                          insurerCoverage: data?.actsTypeName === "EXAM" ? current_patient?.examPercent: current_patient?.consultationPercent || 0,
                        }
                        setForm({
                          ...form,
                          invoiceActsRequests: tempInvoices
                        })
                      }}
                    />
                  </td>
                  {/* <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="number"
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      min="0"
                      name="quantity"
                      onChange={(e) =>
                        handleArrayChange(e, {
                          id: item.id,
                          data: form.invoiceActsRequests,
                          name: "invoiceActsRequests",
                        })
                      }
                    />
                  </td> */}
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="number"
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      min="0"
                      name="quantity"
                      onChange={(e) => {
                        handleArrayChange(e, {
                          id: item.id,
                          data: form.invoiceActsRequests,
                          name: "invoiceActsRequests",
                        })

                        let tempInvoices = form.invoiceActsRequests
                        console.log("INVOICEI: ", tempInvoices[idx])

                        tempInvoices[idx] = {
                          ...tempInvoices[idx],
                          totalInvoice: calculateTotalInvoice(form.invoiceActsRequests[idx], e.target.value),
                          amountPaid: calculateTotal(form.invoiceActsRequests[idx], e.target.value),
                          invoiceAmount: calculateTotal(form.invoiceActsRequests[idx], e.target.value)
                        }
                        setForm({
                          ...form,
                          invoiceActsRequests: tempInvoices
                        })
                      }
                      }
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="number"
                      name="unitPrice"
                      onChange={(e) =>
                        handleArrayChange(e, {
                          id: item.id,
                          data: form.invoiceActsRequests,
                          name: "invoiceActsRequests",
                        })
                      }
                      value={localActs[idx]?.price}
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="number"
                      name="insurerCoverage"
                      value={localActs[idx]?.actsTypeName === "EXAM" ? current_patient?.examPercent: current_patient?.consultationPercent || 0}
                      onChange={(e) =>
                        handleArrayChange(e, {
                          id: item.id,
                          data: form.invoiceActsRequests,
                          name: "invoiceActsRequests",
                        })
                      }
                      // value={form?.invoiceActsRequests[idx].insurerCoverage}
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                    readOnly
                      type="number"
                      name="totalInvoice"
                      value={item.totalInvoice}
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="text"
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      value={item.invoiceAmount}
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <input
                      type="text"
                      className="w-full text-slate-500 py-2 px-3 text-sm focus:bg-transparent border-none text-right ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      value={item.invoiceAmount}
                    />
                  </td>
                  <td className="p-3 border-b border-b-gray-100">
                    <div className="flex items-center justify-center space-x-1">
                      <button
                        onClick={(e) => handleRemoveInvoiceItem(e, item.id)}
                        type="button"
                        className="lc-bon-delete flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"
                      >
                        <i className="bx bx-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleAddInvoiceItem}
              type="button"
              className="flex items-center text-blue-500 hover:bg-blue-100 pr-4 hover:pl-4 py-2 rounded-md disabled:opacity-75 transition-all ease-in-out duration-300"
            >
              <i className="bx bxs-plus-circle pr-2"></i>
              <span className="text-sm">Ajouter</span>
            </button>
          </div>
        </div>
        <div>
          <button
            type="button"
            onClick={(e) => {
              setOpenForm(false)
              handleSubmit(e)
            }}
            className="lc-close-bons-form bg-green-500 hover:bg-green-600 shadow-md text-white text-sm font-medium px-6 py-2.5 mt-8 rounded-md transition-all duration-300"
          >
            Ajouter
          </button>
        </div>
      </form>
      <div className="lc-invoice-container space-y-4">
        {/* {JSON.stringify(invoices)} */}
        {invoices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((invoice, idx) => (
          <AccordionCustom key={idx} title={`Facturation du ${invoice?.createdAt}`}>
          <div className="border rounded-md overflow-hidden hover:translate-y-[2px] transition-all ease-in-out duration-300">
            <div className="px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
              <div className="space-y-6">
                <div className="block w-full overflow-x-auto rounded-md">
                  <table className="w-full">
                    <tbody className="lc-available-tbody">
                      <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                        <td className="px-3 py-3">Date</td>
                        <td className="px-3 py-3">ACTS</td>
                        <td className="px-3 py-3 text-right">QTE</td>
                        <td className="px-3 py-3 text-right">P.U</td>
                        <td className="px-3 py-3 text-right">% PC</td>
                        <td className="px-3 py-3 text-right">Total</td>
                        <td className="px-3 py-3 text-right">MT FACT</td>
                        <td className="px-3 py-3 text-right">MT PAYE</td>
                        <td className="px-3 py-3 text-right">RESTE</td>
                      </tr>
                      {invoice.invoiceActsList.map((item, idx) => (
                          <tr key={idx} className="group/table text-xs sm:text-sm text-slate-500 font-medium hover:bg-blue-50 transition-all ease-in-out duration-300">
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                              {invoice?.createdAt}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                              {item?.acts?.label}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right">
                              {item?.quantity}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right">
                              25000
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right">
                              {item?.insurerCoverage}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right">
                              {item?.totalInvoice}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right text-green-500">
                            {item?.totalInvoice}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right text-black">
                            {item?.totalInvoice}
                            </td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right text-red-500">
                              0
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <button>impression</button> */}
          </div>
          </AccordionCustom>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ invoicesReducer, usersReducer, actsReducer }) => ({
  invoices: invoicesReducer.invoices,
  current_patient: usersReducer.current_patient,
  acts: actsReducer.acts,
  acts_price_insurer: actsReducer.acts_price_insurer
})

export default connect(mapStateToProps)(TabThree);
