import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Assureur from "./components/Assureur";
import AssureurDetails from "./components/AssureurDetails";
import Consultation from "./components/Consultation";
import Examen from "./components/Examen";
import Login from "./components/Login";
import Patient from "./components/Patient";
import PersonnelMedecin from "./components/PersonnelMedecin";
import Pharmacy from "./components/Pharmacy";
import Profile from "./components/Profile";
import Users from "./components/Users";
import { getToken } from "./utils/storageUtils";
import Protected from "./routes/Protected";

function App() {
  // const token = getToken();
  const token = true

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route index element={<Home />} /> */}
          <Route
            path="/users"
            element={
              <Protected isSignedIn={!!token}>
                <Users />
              </Protected>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/examen"
            element={
              <Protected isSignedIn={!!token}>
                <Examen />
              </Protected>
            }
          />
          <Route
            path="/patient"
            element={
              <Protected isSignedIn={!!token}>
                <Patient />
              </Protected>
            }
          />
          <Route
            path="/pharmacy"
            element={
              <Protected isSignedIn={!!token}>
                <Pharmacy />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected isSignedIn={!!token}>
                <Profile />
              </Protected>
            }
          />
          <Route
            path="/consultation"
            element={
              <Protected isSignedIn={!!token}>
                <Consultation />
              </Protected>
            }
          />
          <Route
            path="/assureur"
            element={
              <Protected isSignedIn={!!token}>
                <Assureur />
              </Protected>
            }
          />
          <Route
            path="/assureur-details"
            element={
              <Protected isSignedIn={!!token}>
                <AssureurDetails />
              </Protected>
            }
          />
          <Route
            path="/personnel-medecin"
            element={
              <Protected isSignedIn={!!token}>
                <PersonnelMedecin />
              </Protected>
            }
          />
          <Route path="*" element={<p>Not found</p>} />
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
