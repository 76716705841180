
import * as types from './types';

export const loginUser = (payload) => ({
  type: types.LOGIN_USER_REQUEST,
  payload,
});

export const getUserProfile = () => ({
  type: types.GET_USER_PROFILE_REQUEST
})

export const updateUser = (payload) => ({
  type: types.UPDATE_USER_REQUEST,
  payload
})


export const getUsers = () => ({
  type: types.GET_USERS_REQUEST
})

export const getUsersByFilter = (payload) => ({
  type: types.GET_USERS_BY_FILTER_REQUEST,
  payload
})


export const createUser = (payload) => ({
  type: types.CREATE_USER_REQUEST,
  payload
})

export const logoutUser = () => ({
  type: types.LOGOUT_USER_REQUEST
})

export const setCurrentPatient = (payload) => ({
  type: types.SET_CURRENT_PATIENT,
  payload
})

export const setCurrentInsurer = (payload) => ({
  type: types.SET_CURRENT_INSURER,
  payload
})

export const getPatientCard = (payload) => ({
  type: types.GET_PATIENT_CARD_REQUEST,
  payload
})