/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import Alert from "../../Others/Alert";
import { roles } from "../../../constants/roles";
import Select from "react-select";
import config from "../../../config";
import { connect, useDispatch } from "react-redux";
import { getStoragePatient } from "../../../utils/storageUtils";
import { updateUser } from "../../../redux/users/actions";
import { uploadFile } from "../../../redux/uploads/actions";
import fichePlaceholder from "../../../assets/fiche_placeholder.jpeg"

const storagePatient = getStoragePatient();

const TabOne = ({
  errors,
  current_patient: curr_patient,
  users,
  diseases,
  uploadLink
}) => {
  const dispatch = useDispatch()
  const current_patient = Object.keys(curr_patient).length
    ? curr_patient
    : storagePatient;

  const [update, setUpdate] = useState({
    insurerId: current_patient?.insurer?.id || "",
    medicalFamilyHistoryRequests: [],
    ...current_patient,
  });

  const handleChangeUpdate = (e) =>
    setUpdate((update) => ({
    ...update,
    [e.target.name]: e.target.value,
  }));

  const adaptOptions = (data, historyTypeName) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.name,
      historyTypeName,
      diseasesId: item?.id,
    }));
    return temp;
  };

  const adaptReturnOptions = (data) => {
    let temp = data.map((item) => ({
      value: item.diseases?.id,
      label: item.diseases?.name,
      historyTypeName: item?.historyTypeName,
      diseasesId: item.diseases.id,
    }));

    return temp;
  };
  const adaptSelectOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.name || item?.userName
    }));

    return temp;
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateUser({
        ...update,
        insurerId: insurer?.value,
        doctorId: doctor?.value,
        medicalFamilyHistoryRequests: [
          ...selectedDiseaseFamily,
          ...selectedDiseaseMedical,
        ],
        consent: uploadLink
      })
    );
  };

  const uniqueItems = (data) => {
    const unique = [...new Map(data.map((m) => [m?.diseasesId, m])).values()];
    return unique;
  };

  const [selectedDiseaseMedical, setSelectedDiseaseMedical] = useState(
    uniqueItems([
      ...adaptReturnOptions(current_patient.medicalFamilyHistories).filter(
        (item) => item.historyTypeName === "MEDICAL_HISTORY"
      ),
    ])
  );
  const [selectedDiseaseFamily, setSelectedDiseaseFamily] = useState(
    uniqueItems([
      ...adaptReturnOptions(current_patient.medicalFamilyHistories).filter(
        (item) => item.historyTypeName === "FAMILY_HISTORY"
      ),
    ])
  );

  const [insurer, setInsurer] = useState({
    value: current_patient?.insurer?.id,
    label: current_patient?.insurer?.name,
  })
  const [doctor, setDoctor] = useState({
    value: current_patient?.doctor?.id,
    label: current_patient?.doctor?.userName,
  })
  const [sex, setSex] = useState({
    value: current_patient?.sex,
    label: current_patient?.sex === "F" ? "Mme" : current_patient?.sex === "M" ? "Mr" : "",

  })
  useEffect(() => {
    // console.log("update: ", update)
  }, [update]);

  const insurerOptions = adaptSelectOptions(users.filter((user) => user.roleName === roles.ROLE_INSURER))
  const doctorsOptions = adaptSelectOptions(users.filter((user) => user.roleName === roles.ROLE_DOCTOR))

  const [imageError, setImageError] = useState("")

  return (
    <div
      className="grid grid-cols-2 xl:grid-cols-8 gap-5"
      data-tab="fiche"
    >
      <div className="col-span-2 space-y-1 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
        <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">
          Identifiant unique
        </h3>
        <span className="block uppercase">
          {current_patient?.registrationNumber}
        </span>
      </div>
      <div className="col-span-6">
        <form className="space-y-6 py-5">
          <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">
            Informations patient
          </h4>
          <div>
            {errors.length > 0 &&
              errors.map((error, idx) => (
                <div style={{ marginBottom: 2 }}>
                  <Alert key={idx} message={error.message} />
                </div>
              ))}
          </div>
          <div className="block space-y-5 sm:space-y-0 sm:grid sm:grid-cols-4 xl:grid-cols-12 gap-4">
            <label className="block col-span-1 xl:col-span-2">
              <span className="block text-sm font-medium text-slate-500">
                Titre
              </span>
              {/* <select
                name="sex"
                onChange={handleChangeUpdate}
                value={
                  update?.sex === "F"
                    ? "Mme"
                    : update?.sex === "M"
                    ? "Mr"
                    : ""
                }
                className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
              >
                <option value="M">Mr</option>
                <option value="F">Mme</option>
              </select> */}
              <Select
                options={[{value: "M", label: "Mr"}, {value: "F", label: "Mme"}]}
                onChange={setSex}
                defaultValue={sex}
              />
            </label>
            <label className="block col-span-6 xl:col-span-3">
              <span className="block text-sm font-medium text-slate-500">
                Nom
              </span>
              <input
                type="text"
                name="lastName"
                onChange={handleChangeUpdate}
                value={update?.lastName}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
            <label className="block col-span-7">
              <span className="block text-sm font-medium text-slate-500">
                Prénom
              </span>
              <input
                type="text"
                name="firstName"
                onChange={handleChangeUpdate}
                value={update?.firstName}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
          </div>
          <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Email
              </span>
              <input
                type="text"
                name="email"
                readOnly
                value={update?.email}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Profession
              </span>
              <input
                type="text"
                name="occupation"
                onChange={handleChangeUpdate}
                value={update?.occupation}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
          </div>
          <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Contact
              </span>
              <input
                type="text"
                name="phoneNumber"
                onChange={handleChangeUpdate}
                value={update?.phoneNumber}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Adresse
              </span>
              <input
                type="text"
                name="address"
                onChange={handleChangeUpdate}
                value={update?.address}
                className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
              />
            </label>
          </div>
          <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">
            Autres
          </h4>
          <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Assurée par/Entreprise
              </span>
              {/* <select
                name="insurerId"
                onChange={handleChangeUpdate}
                className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
              >
                <option value={update?.insurer?.id}>
                  {update?.insurer?.name}
                </option>
                {users
                  .filter((user) => user.roleName === roles.ROLE_INSURER)
                  .map((user, idx) => (
                    <option key={idx} value={user?.id}>
                      {user?.name}
                    </option>
                  ))}

              </select> */}
              <Select
                options={insurerOptions}
                onChange={setInsurer}
                defaultValue={insurer}
              />
            </label>
            <label className="block w-auto sm:grow sm:basis-full">
              <span className="block text-sm font-medium text-slate-500">
                Médécin traitant
              </span>
              {/* <input type="text" className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" /> */}
              {/* <select
                name="doctorId"
                onChange={handleChangeUpdate}
                className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
              >
                <option value={update?.doctor?.id}>
                  {update?.doctor?.firstName ||
                    update?.doctor?.lastName}
                </option>
                {users
                  .filter((user) => user.roleName === roles.ROLE_DOCTOR)
                  .map((user, idx) => (
                    <option key={idx} value={user.id}>
                      {user.userName}
                    </option>
                  ))}
              </select> */}
              <Select
                options={doctorsOptions}
                onChange={setDoctor}
                defaultValue={doctor}
              />
            </label>
          </div>
          {update?.insurerId !== "" && (
            <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
              <label className="block sm:grow sm:basis-full">
                <span className="block text-sm font-medium text-slate-500">
                  Pourcentage sur examen du patient
                </span>
                <input
                  name="examPercent"
                  value={update?.examPercent}
                  onChange={handleChangeUpdate}
                  type="number"
                  min={0}
                  className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                />
              </label>
              <label className="block sm:grow sm:basis-full">
                <span className="block text-sm font-medium text-slate-500">
                  Pourcentage sur consultation du patient
                </span>
                <input
                  type="number"
                  min={0}
                  name="consultationPercent"
                  value={update?.consultationPercent}
                  onChange={handleChangeUpdate}
                  className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                />
              </label>
            </div>
          )}
          {/* <!--<div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
                            <label className="block w-auto sm:grow sm:basis-full">
    
                                <span className="block text-sm font-medium text-slate-500">Antécédents familiaux</span>
                                <select className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md" multiple="multiple">
                                    <option value="Grippe">Grippe</option>
    
                                    <option value="Cancer">Cancer</option>
    
                                </select>
        
                            </label>
                            <label className="block w-auto sm:grow sm:basis-full">
                                <span className="block text-sm font-medium text-slate-500">Antécédents médicaux</span>
        
                                <select className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
    
                                    <option value="Cancer">Cancer</option>
                                    <option value="Grippe">Grippe</option>
    
                                </select>
        
                            </label>
                        </div>--> */}
          <label className="block">
            <span className="block text-sm font-medium text-slate-500">
              Antécédents familiaux
            </span>
            <Select
              value={selectedDiseaseFamily}
              onChange={setSelectedDiseaseFamily}
              options={adaptOptions(diseases, "FAMILY_HISTORY")}
              isMulti
            />
            {/* <select className="antecedent-familiaux w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md" multiple="multiple">
                          <option></option>
                          {diseases.map((disease, idx) => (<option key={idx}>{disease?.name}</option>))}
                          <option value="Grippe">Grippe</option>
                          <option value="Cancer">Cancer</option>
                        </select> */}
          </label>
          <label className="block">
            <span className="block text-sm font-medium text-slate-500">
              Antécédents médicaux
            </span>
            <Select
              value={selectedDiseaseMedical}
              onChange={setSelectedDiseaseMedical}
              options={adaptOptions(diseases, "MEDICAL_HISTORY")}
              isMulti
            />
            {/* <select className="antecedent-medicaux w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md" multiple="multiple">
                          {drugs.map((drug, idx) => <option key={idx} value={drug?.id}>{drug?.name}</option>)}
                          <option value="Grippe">Grippe</option>
                          <option value="palu">palu</option>
                        </select> */}
          </label>
          <div className="space-y-3">
            <span className="block text-sm font-medium text-slate-500">
              Fiche de consentement
            </span>
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 md:space-x-9">
              <div className="shrink-0">
                <img
                  className="h-20 w-20 sm:h-24 sm:w-24 object-cover rounded-sm ring-2 ring-offset-4 ring-gray-200 hover:ring-green-200 transition-all ease-in-out duration-300"
                  src={fichePlaceholder}
                  alt="Fiche consentement"
                />
              </div>
              <label className="block">
                <span className="sr-only">Consentement</span>
                {imageError.length > 0 && <Alert message={imageError} />}
                {/* {JSON.stringify(uploadLink)} */}
                <input
                  type="file"
                  onChange={(e) => {
                    var imagedata = document.querySelector('input[type="file"]').files[0];
                    console.log("E: ", imagedata)
                    if(imagedata){
                      if(imagedata.size > 5 * 1024 * 1024) {
                        setImageError("La taille du fichier ne doit pas depasser 5mo")
                      }
                      if(!imagedata.type.match(/\.(jpg|jpeg|png|pdf)$/)) {
                        setImageError("Fichier non supporté")
                      }
                      setImageError("")
                      dispatch(uploadFile(imagedata))
                    }
                  }}
                  className="block w-full text-xs sm:text-sm text-slate-500 file:text-xs sm:file:text-sm file:font-semibold file:py-2 file:px-4 file:bg-green-50 file:text-green-700 file:rounded-full file:border-0 file:mr-4 hover:file:bg-green-100"
                />
              </label>
            </div>
          </div>
          <label className="relative block">
            <span className="block text-sm font-medium text-slate-500">
              Autres à préciser
            </span>
            <textarea className="w-full h-32 text-slate-500 resize-none py-2 pl-3 pr-9 focus:bg-transparent border-none ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"></textarea>
          </label>
          <div className="space-x-1">
            <button
              type="submit"
              onClick={handleSubmitUpdate}
              className="bg-green-500 hover:bg-green-600 shadow-md text-white text-sm mt-12 font-medium px-6 py-2.5 rounded-md transition-all duration-300"
            >
              Enrégistrer
            </button>
            <a
              type="button"
              target="_blank"
              href={`${config.API}/api/users/patient-card-pdf/${current_patient.id}`}
              className="hover:bg-green-100 text-slate-500 hover:text-green-500 text-sm mt-12 font-medium px-6 py-2.5 rounded-md transition-all duration-300"
            >
              Imprimer
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  usersReducer,
  diseasesReducer,
  drugsReducer,
  actsReducer,
  consultationsReducer,
  uploadsReducer
}) => ({
  current_patient: usersReducer.current_patient,
  users: usersReducer.users,
  diseases: diseasesReducer.diseases,
  drugs: drugsReducer.drugs,
  acts: actsReducer.acts,
  consultations: consultationsReducer.consultations,
  errors: consultationsReducer.errors,
  uploadLink: uploadsReducer.link_consentement
});

export default connect(mapStateToProps)(TabOne);
