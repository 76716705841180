import { put, takeLatest } from "redux-saga/effects";
import {
  createActs as createActsService,
  deleteActs as deleteActsService,
  filterActs as filterActsService,
  getActsPriceInsurerFilter as getActsPriceInsurerFilterService,
  createActsPriceInsurer as createActsPriceInsurerService,
  updateActsPriceInsurer as updateActsPriceInsurerService,
  deleteActsPriceInsurer as deleteActsPriceInsurerService
} from "../../services/acts";
import * as types from "./types";
import { toast } from "react-toastify";
import { adaptResponseError } from "../../utils/utils"
import { getStorageInsurer, getToken } from "../../utils/storageUtils";

/**
 * @description login a User Account
 */
const token = getToken()
const storageInsurer = getStorageInsurer()

function* createActs({ payload }) {
  try {
    const data = yield createActsService(payload);
    if (data?.success) {
      toast.success(data?.message)
      yield put({ type: types.CREATE_ACTS_SUCCESS, payload: data.data });
      yield put({ type: types.FETCH_ACTS_REQUEST, payload: {} });
    }
    if (!data.success || data?.status >= 400) {
      toast.error(data?.message)
      yield put({
        type: types.CREATE_ACTS_FAILURE,
        payload: adaptResponseError(data)
      });
    }
  } catch (error) {
    console.error("Create acts error: ", error);
  }
}

function* fetchAllActs({ payload }) {
  try {
    const data = yield filterActsService(payload);
    console.log("data is", data);
    if (data?.success) {
      // toast.success(data?.message)
      yield put({ type: types.FETCH_ACTS_SUCCESS, payload: data.data });
      //alert("acte pris avec succès avec succès")
    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.FETCH_ACTS_FAILURE,
        payload: "une erreur est arrivée",
      });
    }
  } catch (error) {
    console.error("fetch acts error: ", error);
  }
}

function* deleteActs({ payload }) {
  try {
    const data = yield deleteActsService(payload);
    if (data?.success) {
      toast.success(data?.message)
      yield put({ type: types.DELETE_ACTS_SUCCESS, payload: data.data });
      yield put({ type: types.FETCH_ACTS_REQUEST, payload: {} });
    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.DELETE_ACTS_FAILURE,
        payload: "une erreur est arrivée",
      });
    }
  } catch (error) {
    console.error("delete acts error: ", error);
  }
}

function* getActsPriceInsurerFilter({ payload }) {
  try {
    const data = yield getActsPriceInsurerFilterService(payload, token);
    console.log("data is", data);
    if (data?.success) {
      // toast.success(data?.message)
      yield put({ type: types.GET_ACTS_PRICE_INSURER_FILTER_SUCCESS, payload: data.data });
    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.GET_ACTS_PRICE_INSURER_FILTER_FAILURE,
        payload: []
      });
    }
  } catch (error) {
    console.error("getActsPriceInsurerFilterError: ", error);
  }
}

function* createActsPriceInsurer({ payload }) {
  try {
    const data = yield createActsPriceInsurerService(payload, token);
    console.log("SAGA_DATA", data);
    if (data?.success) {
      toast.success(data?.message)
      yield put({ type: types.CREATE_ACTS_PRICE_INSURER_SUCCESS, payload: data.data });
      yield put({ type: types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST, payload: { insurerId: storageInsurer?.id} });

    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.CREATE_ACTS_PRICE_INSURER_FAILURE,
        payload: adaptResponseError(data)
      });
    }
  } catch (error) {
    console.error("createActsPriceInsurerError: ", error);
  }
}

function* updateActsPriceInsurer({ payload }) {
  try {
    const data = yield updateActsPriceInsurerService(payload, token);
    console.log("SAGA_DATA", data);
    if (data?.success) {
      toast.success(data?.message)
      yield put({ type: types.UPDATE_ACTS_PRICE_INSURER_SUCCESS, payload: data.data });
      yield put({ type: types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST, payload: { insurerId: storageInsurer?.id} });

    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.UPDATE_ACTS_PRICE_INSURER_FAILURE,
        payload: adaptResponseError(data)
      });
    }
  } catch (error) {
    console.error("updateActsPriceInsurerError: ", error);
  }
}

function* deleteActsPriceInsurer({ payload }) {
  try {
    const data = yield deleteActsPriceInsurerService(payload, token);
    console.log("SAGA_DATA", data);
    if (data?.success) {
      toast.success(data?.message)
      yield put({ type: types.DELETE_ACTS_PRICE_INSURER_SUCCESS, payload: data.data });
      yield put({ type: types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST, payload: { insurerId: storageInsurer?.id} });

    }
    if (!data.success) {
      toast.error(data?.message)
      yield put({
        type: types.DELETE_ACTS_PRICE_INSURER_FAILURE,
        payload: adaptResponseError(data)
      });
    }
  } catch (error) {
    console.error("deleteActsPriceInsurerError: ", error);
  }
}

export default function* ActsSaga() {
  yield takeLatest(types.CREATE_ACTS_REQUEST, createActs);
  yield takeLatest(types.FETCH_ACTS_REQUEST, fetchAllActs);
  yield takeLatest(types.DELETE_ACTS_REQUEST, deleteActs);
  yield takeLatest(types.GET_ACTS_PRICE_INSURER_FILTER_REQUEST, getActsPriceInsurerFilter);
  yield takeLatest(types.CREATE_ACTS_PRICE_INSURER_REQUEST, createActsPriceInsurer);
  yield takeLatest(types.UPDATE_ACTS_PRICE_INSURER_REQUEST, updateActsPriceInsurer);
  yield takeLatest(types.DELETE_ACTS_PRICE_INSURER_REQUEST, deleteActsPriceInsurer);
}
