/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import TimePicker from "react-time-picker";
import AccordionCustom from "../../material/Accordion";
import { connect, useDispatch } from "react-redux";
import { getStoragePatient } from "../../../utils/storageUtils";
import { createConsultation } from "../../../redux/consultations/actions";
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { fetchAllActs } from "../../../redux/acts/actions";
import { act } from "@testing-library/react";
import { Autocomplete, TextField } from "@mui/material";
import config from "../../../config";

const storagePatient = getStoragePatient();

const TabTwo = ({
  current_patient: curr_patient,
  acts,
  drugs,
  consultations,
  errors
}) => {

  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(false)
  const current_patient = Object.keys(curr_patient).length
  ? curr_patient
  : storagePatient;
  const [form, setForm] = useState({
    actsDiagnosticRequests: [
      {
        id: 0,
        actsId: null,
        eyeTypeName: "",
        result: "",
        resultFile: "",
      },
    ],
    dosageRequests: [
      {
        id: 0,
        drugsId: 0,
        frequency: "",
        quantity: 0,
      },
    ],
    doctorId: current_patient?.doctor?.id,
    patientId: current_patient?.id,
    appointmentTime: "10:00",
  });

  const exam = {
    id: 0,
    actsId: null,
    eyeTypeName: "",
    result: "",
    resultFile: "",
  };

  const drug = {
    id: 0,
    drugsId: null,
    frequency: "",
    quantity: 0,
  };

  const generateId = (data) => {
    if (data.length === 0) {
      return 0;
    } else {
      return Number(data[data.length - 1].id) + 1;
    }
  };

  const handleTimeChange = (val) => {
    console.log("TIMER: ", val);
    setForm((form) => ({
      ...form,
      appointmentTime: val,
    }));
  };

  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

    const handleArrayChange = (e, { id, data, name }) => {
      let temp = data;
      const find = data.find((i) => i.id === id);
      const index = data.indexOf(find);
      temp[index] = {
        ...data[index],
        [e.target.name]: e.target.value,
      };
      setForm({
        ...form,
        [name]: temp,
      });
    };

  const handleAddDrugs = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      dosageRequests: [
        ...form.dosageRequests,
        { ...drug, id: generateId(form.dosageRequests) },
      ],
    });
  };

  const handleRemoveDrug = (e, idx) => {
    e.preventDefault();
    let tempDrugs = form.dosageRequests.filter((i) => i.id !== idx);
    setForm({
      ...form,
      dosageRequests: tempDrugs,
    });
  };

  const handleAddActs = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      actsDiagnosticRequests: [
        ...form.actsDiagnosticRequests,
        { ...exam, id: generateId(form.actsDiagnosticRequests) },
      ],
    });
  };

  const handleRemoveAct = (e, idx) => {
    e.preventDefault();
    let tempActs = form.actsDiagnosticRequests.filter((i) => i.id !== idx);
    setForm({
      ...form,
      actsDiagnosticRequests: tempActs,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form: ", form);
    // console.log("doctorId: ", current_patient.doctor.id)

    dispatch(createConsultation(form));
  };

  const opts = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  const filterColors = (inputValue) => {
    return acts.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const adaptSelectActsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.label
    }));

    return temp;
  };

  const adaptSelectDrugsOptions = (data) => {
    let temp = data.map((item) => ({
      value: item?.id,
      label: item?.name
    }));

    return temp;
  };

  return (
    <div className="lc-tab-target md:px-5 py-4" data-tab="soins">
      <button
        type="button"
        onClick={() => setOpenForm(true)}
        className="lc-new-consultation bg-green-500 hover:bg-green-600 mb-6 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300"
      >
        Nouvelle consulation
      </button>
      <form className={`${openForm ? "" : "hidden"} lc-parcours-form space-y-5 mb-6`}>
        <label className="block">
          <span className="block text-sm font-medium text-slate-500">
            Motif <span className="text-red-600">*</span>
          </span>
          <input
            type="text"
            name="complaint"
            onChange={handleChange}
            className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
          />
        </label>
        <div className="block space-y-5">
          <h3 className="text-lg sm:text-xl text-slate-700 font-medium">
            Examens
          </h3>
          <div className="block w-full overflow-x-auto rounded-md">
            <table className="w-full">
              <tbody className="lc-available-tbody">
                <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                  <td className="px-3 py-3">Acts</td>
                  <td className="px-3 py-3 whitespace-nowrap">Résultat œil gauche</td>
                  <td className="px-3 py-3 whitespace-nowrap">Résultat œil droit</td>
                  <td className="px-3 py-3">Fichier</td>
                  <td className="px-3 py-3"></td>
                </tr>
                {form.actsDiagnosticRequests.map((item, idx) => (
                  <tr key={idx} className="lc-exam-row">
                    <td className="p-3 border-b border-b-gray-100">
                      {/* <select
                        name="actsId"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.actsDiagnosticRequests,
                            name: "actsDiagnosticRequests",
                          })
                        }
                        className="w-[200px] text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      >
                        <option value="">Act</option>
                        {acts.map((act, idx) => (
                          <option key={act + idx} value={act.id}>
                            {act?.label}
                          </option>
                        ))}
                      </select> */}
                      {/* <Select
                        isSearchable={true}
                        options={adaptSelectActsOptions(acts)}
                        onChange={setActSelect}
                      /> */}
                         <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          clearText={<></>}
                          clearIcon={<></>}
                          options={adaptSelectActsOptions(acts)}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="act" />}
                          onChange={(e, val) => {
                            let tempActs = form.actsDiagnosticRequests
                            tempActs[idx] = {
                              ...form.actsDiagnosticRequests[idx],
                              actsId: val.value
                            }
                            setForm({
                              ...form,
                              actsDiagnosticRequests: tempActs
                            })
                          }}
                        />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      {/* <select
                        name="resultEyeLeft"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.actsDiagnosticRequests,
                            name: "actsDiagnosticRequests",
                          })
                        }
                        className="w-[200px] text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      >
                        <option value=""> Résultat œil gauche</option>
                        <option value="EYE_LEFT">EYE_LEFT</option>
                        <option value="EYE_RIGHT">EYE_RIGHT</option>
                      </select> */}
                      <input
                        type="text"
                        name="resultEyeLeft"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.actsDiagnosticRequests,
                            name: "actsDiagnosticRequests",
                          })
                        }
                        className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <input
                        type="text"
                        name="resultEyeRight"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.actsDiagnosticRequests,
                            name: "actsDiagnosticRequests",
                          })
                        }
                        className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <div className="min-w-[320px] lg:min-w-full flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 md:space-x-9">
                        <label className="block">
                          <span className="sr-only">Fichier</span>
                          <input
                            type="file"
                            className="block w-full text-xs sm:text-sm text-slate-500 file:text-xs sm:file:text-sm file:font-semibold file:py-2 file:px-4 file:bg-green-50 file:text-green-700 file:rounded-full file:border-0 file:mr-4 hover:file:bg-green-100"
                          />
                        </label>
                      </div>
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <div className="flex items-center justify-center space-x-1">
                        <button
                          onClick={(e) => handleRemoveAct(e, idx)}
                          type="button"
                          className="lc-exam-delete flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleAddActs}
              type="button"
              className="flex items-center text-blue-500 hover:bg-blue-100 pr-4 hover:pl-4 py-2 rounded-md disabled:opacity-75 transition-all ease-in-out duration-300"
            >
              <i className="bx bxs-plus-circle pr-2"></i>
              <span className="text-sm">Ajouter</span>
            </button>
          </div>
        </div>
        <label className="relative block">
          <span className="block text-sm font-medium text-slate-500">
            Diagonotics <span className="text-red-600">*</span>
          </span>
          <textarea
            name="diagnostic"
            onChange={handleChange}
            className="w-full h-32 text-slate-500 resize-none py-2 pl-3 pr-9 focus:bg-transparent border-none ring-1 focus:ring-2 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
          ></textarea>
        </label>
        {/* <div className="block space-y-5">
                      <h3 className="text-lg sm:text-xl text-slate-700 font-medium">Ordonnances des lunettes</h3>
                      <div className="block w-full overflow-x-auto rounded-md">
                        <table className="w-full border-collapse border-2 border-slate-400">
                          <tbody className="">
                            <tr className="text-sm sm:text-base text-black font-medium">
                              <td className="px-3 py-3 border border-slate-400"></td>
                              <th scope="col" colspan="3" className="px-3 py-3 uppercase border border-slate-400">OD</th>
                              <th scope="col" colspan="3" className="px-3 py-3 uppercase border border-slate-400">OG</th>
                            </tr>
                            <tr className="text-xs sm:text-sm">
                              <th scope="row" className="border border-slate-400 p-3"></th>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Sphère</td>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Cylindre</td>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Axe</td>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Sphère</td>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Cylindre</td>
                              <td className="min-w-[112px] text-center border border-slate-400 p-3 text-slate-700">Axe</td>
                            </tr>
                            <tr className="text-xs sm:text-sm">
                              <th scope="row" className="h-[60px] border border-slate-400 p-3 text-slate-700 text-left whitespace-nowrap">Vision de loin</th>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                            </tr>
                            <tr className="text-xs sm:text-sm">
                              <th scope="row" className="h-[60px] border border-slate-400 p-3 text-slate-700 text-left whitespace-nowrap">Vision toute distance</th>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                            </tr>
                            <tr className="text-xs sm:text-sm">
                              <th scope="row" className="h-[60px] border border-slate-400 p-3 text-slate-700 text-left whitespace-nowrap">Vision de près</th>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                              <td className="h-[60px] border border-slate-400 p-1"><input type="text" className="h-full w-full text-slate-500 px-2 py-0 text-center text-xs focus:bg-transparent border-none focus:ring-0 focus:ring-transparent" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col lg:flex-row justify-between border-2 border-slate-400 rounded-md">
                        <div className="px-6 py-4">
                          <h3 className="text-sm text-slate-700 font-semibold uppercase mb-6">Bi - temporale</h3>
                          <ul className="space-y-3 pl-5  sm:pl-10">
                            <li className="list-disc">
                              <label className="flex items-center space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Longueur des branches</span>
                                <input type="text" className="text-slate-500 w-5 py-2 px-6 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Distance interpupillaire</span>
                                <input type="text" className="text-slate-500 w-5 py-2 px-6 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <p className="block text-sm font-medium text-slate-700 mb-4">Teinte</p>
                              <div className="flex items-center space-x-3 pl-4">
                                <label className="flex items-center space-x-3">
                                  <span className="block text-sm font-medium text-slate-500">Blanc</span>
                                  <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                                </label>
                                <label className="flex items-center space-x-3">
                                  <span className="block text-sm font-medium text-slate-500">PGx</span>
                                  <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                                </label>
                                <label className="flex items-center space-x-3">
                                  <span className="block text-sm font-medium text-slate-500">PGx</span>
                                  <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                                </label>
                              </div>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Double foyers</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Progressifs</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div className="lg:border-l-2 border-slate-400 px-6 lg:pl-8 lg:pr-20 py-4">
                          <h3 className="text-sm text-slate-700 font-semibold uppercase mb-6">Prisme</h3>
                          <ul className="space-y-3 pl-5 sm:pl-10">
                            <li className="list-disc">
                              <label className="flex items-center justify-between space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Minéral</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center justify-between space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Organique</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center justify-between space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Transition</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center justify-between space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Transtion + AR</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                            <li className="list-disc">
                              <label className="flex items-center justify-between space-x-3">
                                <span className="block text-sm font-medium text-slate-500">Transtion + Anti-lumière bleu</span>
                                <input type="checkbox" className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
        <div className="block space-y-5">
          <h3 className="text-lg sm:text-xl text-slate-700 font-medium">
            Ordonnance médicale
          </h3>
          <div className="block w-full overflow-x-auto rounded-md">
            <table className="w-full">
              <tbody className="lc-available-tbody">
                <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                  <td className="px-3 py-3">Médicaments</td>
                  <td className="px-3 py-3 whitespace-nowrap">Quantity</td>
                  <td className="px-3 py-3 whitespace-nowrap">Frequency</td>
                  <td className="px-3 py-3"></td>
                </tr>
                {/* <tr className="lc-order-row">
                              <td className="p-3 border-b border-b-gray-100">
                                <select name="drugsId" onChange={handleChange} className="w-[112px] sm:w-full text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                                  <option value="Moxicon">Moxicon</option>
                                  <option value="Moxicon DX collyre">Moxicon DX collyre</option>
                                </select>
                              </td>
                              <td className="p-3 border-b border-b-gray-100"><input type="text" name="frequency" onChange={handleChange} className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md" /></td>
                              <td className="p-3 border-b border-b-gray-100"><input type="text" name="quantity" onChange={handleChange} className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md" /></td>
                              <td className="p-3 border-b border-b-gray-100">
                                <div className="flex items-center justify-center space-x-1">
                                  <button type="button" className="lc-order-delete flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className="bx bx-trash"></i></button>
                                </div>
                              </td>
                            </tr> */}
                {form.dosageRequests.map((item, idx) => (
                  <tr key={idx} className="lc-order-row">
                    <td className="p-3 border-b border-b-gray-100">
                      {/* <select
                        name="drugsId"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.dosageRequests,
                            name: "dosageRequests",
                          })
                        }
                        className="w-[112px] sm:w-full text-slate-500 py-[0.522rem] px-3 text-sm border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      >
                        <option value="">Médicament</option>
                        {drugs.map((drug, idx) => (
                          <option key={idx} value={drug?.id}>
                            {drug?.name}
                          </option>
                        ))}
                      </select> */}
                      <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          clearText={<></>}
                          clearIcon={<></>}
                          options={adaptSelectDrugsOptions(drugs)}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="medicament" />}
                          onChange={(e, val) => {
                            let tempDrugs = form.dosageRequests
                            tempDrugs[idx] = {
                              ...form.dosageRequests[idx],
                              drugsId: val.value
                            }
                            setForm({
                              ...form,
                              dosageRequests: tempDrugs
                            })
                          }}
                        />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <input
                        type="number"
                        min={0}
                        name="quantity"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.dosageRequests,
                            name: "dosageRequests",
                          })
                        }
                        className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <textarea
                        type="text"
                        name="frequency"
                        onChange={(e) =>
                          handleArrayChange(e, {
                            id: item.id,
                            data: form.dosageRequests,
                            name: "dosageRequests",
                          })
                        }
                        className="w-full text-slate-500 py-2 px-3 bg-gray-100 text-sm focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                      />
                    </td>
                    <td className="p-3 border-b border-b-gray-100">
                      <div className="flex items-center justify-center space-x-1">
                        <button
                          type="button"
                          onClick={(e) => handleRemoveDrug(e, item.id)}
                          className="flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300"
                        >
                          <i className="bx bx-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleAddDrugs}
              className="flex items-center text-blue-500 hover:bg-blue-100 pr-4 hover:pl-4 py-2 rounded-md disabled:opacity-75 transition-all ease-in-out duration-300"
            >
              <i className="bx bxs-plus-circle pr-2"></i>
              <span className="text-sm">Ajouter</span>
            </button>
          </div>
        </div>
        <div className="space-y-5 sm:space-y-0 sm:flex sm:space-x-4">
          <label className="block w-auto sm:grow sm:basis-full">
            <span className="block text-sm font-medium text-slate-500">
              Prochain rendez-vous
            </span>
            {/* <input min={new Date().getFullYear() + "-" + (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1): new Date().getMonth() + 1) + "-" + new Date().getDate() } type="date" name="appointmentDate" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" /> */}
            <input
              type="date"
              name="appointmentDate"
              onChange={handleChange}
              className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
            />
          </label>
          <label className="block w-auto sm:grow sm:basis-full">
            <span className="block text-sm font-medium text-slate-500">
              Heure/Minute
            </span>
            {/* <input type="time" name="appointmentTime" onInput={handleChange} className="w-full text-slate-500 py-2 px-3 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" /> */}
            <TimePicker
              onChange={handleTimeChange}
              value={form?.appointmentTime}
              className="w-full text-slate-500 py-2 px-3 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
            />
          </label>
        </div>
        <div>
          <button
            type="button"
            onClick={(e) => {
              handleSubmit(e)
              setOpenForm(false)
            }}
            className="lc-close-parcours-forms bg-green-500 hover:bg-green-600 shadow-md text-white text-sm font-medium px-6 py-2.5 mt-8 rounded-md transition-all duration-300"
          >
            Cloturer consulation
          </button>
        </div>
      </form>
      <div className="lc-consultation-container space-y-4">
        {consultations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, idx) => (
          <AccordionCustom
            key={idx}
            title={"Consultation du " + item.createdAt}
          >
            <div className="">
              <div className="grid grid-cols-2 xl:grid-cols-8 gap-5">
                <div className="col-span-6 sm:col-span-2 space-y-5 md:space-y-16 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
                  <div className="flex flex-col space-y-5">
                    <div className="space-y-1">
                      <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">
                        Prochain Rendez-vous
                      </h3>
                      <span className="block font-extrabold uppercase">
                        {item?.appointmentDate} - {item?.appointmentTime}
                      </span>
                    </div>
                    <div className="space-y-1">
                      <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">
                        Motif
                      </h3>
                      <span className="block text-sm text-slate-800 uppercase">
                        {item?.complaint}
                      </span>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">
                      Diagonotics
                    </h3>
                    <p className="block text-sm text-slate-700">
                      {item?.diagnostic}
                    </p>
                  </div>
                </div>
                <div className="col-span-6 space-y-8">
                  <div className="space-y-8">
                    <div className="space-y-6">
                      <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">
                        Examens
                      </h4>
                      <ul className="relative pl-10 space-y-3">
                        {item?.actsDiagnosticList.map((elem, idx) => (
                          <li key={idx} className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                          <i className="bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500"></i>
                          <span className="uppercase text-slate-600 text-sm font-bold">
                            {elem?.acts?.label}
                          </span>
                          <ul className="pt-2 pl-6 space-y-2">
                            <li className="text-slate-500 text-sm list-disc">
                              Résultat œil droit:{" "}
                              <span className="text-base font-extrabold">
                                {elem?.resultEyeRight}
                              </span>
                            </li>
                            <li className="text-slate-500 text-sm list-disc">
                              Résultat œil gauche:{" "}
                              <span className="text-base font-extrabold">
                              {elem?.resultEyeLeft}
                              </span>
                            </li>
                            {elem?.resultFile && <li className="text-slate-500 text-sm list-disc">
                              <a href="#" className="underline text-blue-500">
                                {" "}
                                voir le fichier
                              </a>
                            </li>}
                          </ul>
                        </li>
                        ))}
                        {/* <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="uppercase text-slate-600 text-sm font-bold">Fond d'œil</span>
                                        <ul className="pt-2 pl-6 space-y-2">
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                          <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                        </ul>
                                      </li> */}
                      </ul>
                    </div>
                    <div className="space-y-6">
                      <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">
                        Ordonnances Medicale{" "}
                        <a
                          type="button"
                          target="_blank"
                          href={`${config.API}/api/consultation/prescription-card-pdf/${item?.id}`}
                          className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"
                        >
                          <i className="bx bx-cloud-download"></i>
                        </a>
                      </h4>
                      <ul className="relative pl-10 space-y-3">
                        {item?.medicalPrescription?.dosageList.map(
                          (ordonance, idx) => (
                            <li
                              key={idx}
                              className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden"
                            >
                              <i className="bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500"></i>
                              <span className="text-slate-600 text-sm">
                                <span className="font-semibold">
                                  {ordonance?.drugs?.name}:
                                </span>
                                {ordonance?.frequency}
                              </span>
                            </li>
                          )
                        )}
                        {/* <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                      </li>
                                      <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                      </li>
                                      <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                      </li> */}
                      </ul>
                    </div>
                    {/* <div className="space-y-6">
                                    <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnance médicale <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                    <ul className="relative pl-10 space-y-3">
                                      <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="uppercase text-slate-600 text-sm font-bold">Moxicon DX collyre</span>
                                        <ul className="pt-2 pl-6 space-y-2">
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                        </ul>
                                      </li>
                                      <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                        <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                        <span className="uppercase text-slate-600 text-sm font-bold">Moxicon</span>
                                        <ul className="pt-2 pl-6 space-y-2">
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                          <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div> */}
                  </div>
                  {/* <button type="button" className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300">Mettre à jour</button> */}
                </div>
              </div>
            </div>
          </AccordionCustom>
        ))}
        {/* <div className="lc-accordion-item border rounded-md overflow-hidden hover:translate-y-[2px] transition-all ease-in-out duration-300">
                      <header className="lc-accordion-header flex items-center justify-between cursor-pointer px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <span className="text-[13px] text-slate-900 font-bold px-0 py-4">Consultation du 22/01/2023 à 07:15</span>
                        <i className='lc-accordion-icon bx bxs-chevron-down flex items-center justify-center h-8 w-8 text-xl rounded-full hover:bg-gray-100 text-slate-500 transition-all ease-in-out duration-300'></i>
                      </header>
                      <div className="lc-accordion-body h-0 px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <div className="grid grid-cols-2 xl:grid-cols-8 gap-5">
                          <div className="col-span-6 sm:col-span-2 space-y-5 md:space-y-16 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
                            <div className="flex flex-col space-y-5">
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Prochain Rendez-vous</h3>
                                <span className="block font-extrabold uppercase">9/10/23 - 14h00</span>
                              </div>
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Motif</h3>
                                <span className="block text-sm text-slate-800 uppercase">Rougeur, Purit</span>
                              </div>
                            </div>
                            <div className="space-y-1">
                              <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Diagonotics</h3>
                              <p className="block text-sm text-slate-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque molestiae aliquam minima delectus neque facilis.</p>
                            </div>
                          </div>
                          <div className="col-span-6 space-y-8">
                            <div className="space-y-8">
                              <div className="space-y-6">
                                <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Examens</h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Acculté visuelle</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Fond d'œil</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnances des lunettes <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnance médicale <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon DX collyre</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300">Mettre à jour</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lc-accordion-item border rounded-md overflow-hidden hover:translate-y-[2px] transition-all ease-in-out duration-300">
                      <header className="lc-accordion-header flex items-center justify-between cursor-pointer px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <span className="text-[13px] text-slate-900 font-bold px-0 py-4">Consultation du 22/01/2023 à 07:15</span>
                        <i className='lc-accordion-icon bx bxs-chevron-down flex items-center justify-center h-8 w-8 text-xl rounded-full hover:bg-gray-100 text-slate-500 transition-all ease-in-out duration-300'></i>
                      </header>
                      <div className="lc-accordion-body h-0 px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <div className="grid grid-cols-2 xl:grid-cols-8 gap-5">
                          <div className="col-span-6 sm:col-span-2 space-y-5 md:space-y-16 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
                            <div className="flex flex-col space-y-5">
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Prochain Rendez-vous</h3>
                                <span className="block font-extrabold uppercase">9/10/23 - 14h00</span>
                              </div>
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Motif</h3>
                                <span className="block text-sm text-slate-800 uppercase">Rougeur, Purit</span>
                              </div>
                            </div>
                            <div className="space-y-1">
                              <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Diagonotics</h3>
                              <p className="block text-sm text-slate-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque molestiae aliquam minima delectus neque facilis.</p>
                            </div>
                          </div>
                          <div className="col-span-6 space-y-8">
                            <div className="space-y-8">
                              <div className="space-y-6">
                                <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Examens</h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Acculté visuelle</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Fond d'œil</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnances des lunettes <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnance médicale <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon DX collyre</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300">Mettre à jour</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lc-accordion-item border rounded-md overflow-hidden hover:translate-y-[2px] transition-all ease-in-out duration-300">
                      <header className="lc-accordion-header flex items-center justify-between cursor-pointer px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <span className="text-[13px] text-slate-900 font-bold px-0 py-4">Consultation du 22/01/2023 à 07:15</span>
                        <i className='lc-accordion-icon bx bxs-chevron-down flex items-center justify-center h-8 w-8 text-xl rounded-full hover:bg-gray-100 text-slate-500 transition-all ease-in-out duration-300'></i>
                      </header>
                      <div className="lc-accordion-body h-0 px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <div className="grid grid-cols-2 xl:grid-cols-8 gap-5">
                          <div className="col-span-6 sm:col-span-2 space-y-5 md:space-y-16 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
                            <div className="flex flex-col space-y-5">
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Prochain Rendez-vous</h3>
                                <span className="block font-extrabold uppercase">9/10/23 - 14h00</span>
                              </div>
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Motif</h3>
                                <span className="block text-sm text-slate-800 uppercase">Rougeur, Purit</span>
                              </div>
                            </div>
                            <div className="space-y-1">
                              <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Diagonotics</h3>
                              <p className="block text-sm text-slate-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque molestiae aliquam minima delectus neque facilis.</p>
                            </div>
                          </div>
                          <div className="col-span-6 space-y-8">
                            <div className="space-y-8">
                              <div className="space-y-6">
                                <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Examens</h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Acculté visuelle</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Fond d'œil</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnances des lunettes <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnance médicale <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon DX collyre</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300">Mettre à jour</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lc-accordion-item border rounded-md overflow-hidden hover:translate-y-[2px] transition-all ease-in-out duration-300">
                      <header className="lc-accordion-header flex items-center justify-between cursor-pointer px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <span className="text-[13px] text-slate-900 font-bold px-0 py-4">Consultation du 22/01/2023 à 07:15</span>
                        <i className='lc-accordion-icon bx bxs-chevron-down flex items-center justify-center h-8 w-8 text-xl rounded-full hover:bg-gray-100 text-slate-500 transition-all ease-in-out duration-300'></i>
                      </header>
                      <div className="lc-accordion-body h-0 px-3 sm:px-6 py-0 transition-all ease-in-out duration-300">
                        <div className="grid grid-cols-2 xl:grid-cols-8 gap-5">
                          <div className="col-span-6 sm:col-span-2 space-y-5 md:space-y-16 pb-3 xl:pb-0 xl:border-r pr-0 xl:pr-3 py-2">
                            <div className="flex flex-col space-y-5">
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Prochain Rendez-vous</h3>
                                <span className="block font-extrabold uppercase">9/10/23 - 14h00</span>
                              </div>
                              <div className="space-y-1">
                                <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Motif</h3>
                                <span className="block text-sm text-slate-800 uppercase">Rougeur, Purit</span>
                              </div>
                            </div>
                            <div className="space-y-1">
                              <h3 className="text-xs font-black text-slate-500 tracking-wide uppercase">Diagonotics</h3>
                              <p className="block text-sm text-slate-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque molestiae aliquam minima delectus neque facilis.</p>
                            </div>
                          </div>
                          <div className="col-span-6 space-y-8">
                            <div className="space-y-8">
                              <div className="space-y-6">
                                <h4 className="text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Examens</h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Acculté visuelle</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Fond d'œil</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc"><a href="#" className="underline text-blue-500"> voir le fichier</a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnances des lunettes <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon goutte:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="text-slate-600 text-sm"><span className="font-semibold">Moxicon DX collyre:</span> 1 goutte et soir pendant 15jours</span>
                                  </li>
                                </ul>
                              </div>
                              <div className="space-y-6">
                                <h4 className="flex justify-between text-sm font-bold text-slate-500 tracking-wide border-b pb-3">Ordonnance médicale <button type="button" className="flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-xl cursor-pointer rounded-full transition-all ease-in-out duration-300"><i className='bx bx-cloud-download'></i></button></h4>
                                <ul className="relative pl-10 space-y-3">
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon DX collyre</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                  <li className="relative before:content-[''] before:absolute before:-left-[23px] before:top-[19px] before:h-[calc(100%-4px)] before:w-[2px] before:bg-green-400 before:last:hidden">
                                    <i className='bx bx-check-circle absolute -left-8 top-[2px] h-5 w-5 flex items-center justify-center text-[22px] text-green-500' ></i>
                                    <span className="uppercase text-slate-600 text-sm font-bold">Moxicon</span>
                                    <ul className="pt-2 pl-6 space-y-2">
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil droit: <span className="text-base font-extrabold">Bien</span></li>
                                      <li className="text-slate-500 text-sm list-disc">Résultat œil gauche: <span className="text-base font-extrabold">Bien</span></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <button type="button" className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-sm font-medium px-6 py-2.5 rounded-md transition-all duration-300">Mettre à jour</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  usersReducer,
  diseasesReducer,
  drugsReducer,
  actsReducer,
  consultationsReducer,
}) => ({
  current_patient: usersReducer.current_patient,
  users: usersReducer.users,
  diseases: diseasesReducer.diseases,
  drugs: drugsReducer.drugs,
  acts: actsReducer.acts,
  consultations: consultationsReducer.consultations,
  errors: consultationsReducer.errors,
});

export default connect(mapStateToProps)(TabTwo);
