import * as types from "./types"

export const createConsultation = (payload) => ({
  type: types.CREATE_CONSULTATION_REQUEST,
  payload
})

export const getConsultationsByFilter = (payload) => ({
  type: types.GET_CONSULTATIONS_BY_FILTER_REQUEST,
  payload
})