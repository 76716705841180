import config from '../../config';

// var myHeaders = new Headers();
// myHeaders.append('Content-Type', 'application/json');

/**
 *
 * @param {*} payload
 * @returns user details
 */
export const uploadFile = async (payload) => {
  const formdata = new FormData();

  formdata.append("photo", payload);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  try {
    const request = await fetch("http://api.drtoho.com/uploadsupload", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('UploadFileServiceErr: ', error);
  }
}