import config from '../../config';
import { logoutHelper } from '../../utils/logoutHelper';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

/**
 *
 * @param {*} payload
 * @returns invoice details
 */
export const createInvoice = async (payload, token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/invoice", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('CreateInvoiceServiceErr: ', error);
    logoutHelper()
  }
}


export const getInvoiceByFilter = async (payload, token) => {
  var raw = JSON.stringify(payload);

  var requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow'
  };
  try {

    const request = await fetch(config.API + "/api/invoice/get-filter", requestOptions)
    const response = await request.json()
    return response
  }
  catch (err) {
    console.error('GetInvoiceByFilterServiceErr: ', err);
    // logoutHelper()
  }
}