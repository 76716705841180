/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DashboardWrapper from "../Layout/DashboardWrapper";
import { connect, useDispatch } from "react-redux";
import { createUser, getUsersByFilter } from "../../redux/users/actions";
import { roles } from "../../constants/roles";
import Alert from "../Others/Alert";
import ModalUI from "../material/Modal";
import AvatarMui from "../material/Avatar";
import Pagination from "../Others/Pagination";

const PersonnelMedecin = ({ users, getting_users, errors }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    roleName: roles.ROLE_DOCTOR,
  });
  const [search, setSearch] = useState("")

  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("FORM: ", form);
    dispatch(createUser(form));
    setOpenFormModal(false)
  };

  useEffect(() => {
    dispatch(getUsersByFilter({}));
  }, []);

  const [data, setData] = useState(users.filter(user => (user.roleName === roles.ROLE_DOCTOR)))
  const [isOpen, setIsOpen] = useState(false);
  const [localDoctor, setLocalDoctor] = useState()
  const [openFormModal, setOpenFormModal] = useState(false)

  return (
    <>
      <DashboardWrapper showSpinner={getting_users}>
        <ModalUI
          isOpen={isOpen}
          styles={{
            top: 0,
            left: "unset",
            transform: "unset",
            right: 0,
            height: "100%",
            width: "25%",
          }}
          onClose={() => setIsOpen(false)}
        >
          <div>
            <span
              className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300"
              data-lc-target="#profile-1"
            >
              <i className="bx bx-x"></i>
            </span>
            <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
              <div className="relative">
                <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-8 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">
                  Profile
                </h4>
                <div className="flex flex-col items-center justify-center text-center">
                  <div className="relative">
                    {/* <img
                      className="h-w-32 w-32 ring-2 ring-offset-2 ring-gray-200 rounded-full"
                      src="./images/profil-1.png"
                      alt=""
                    /> */}
                    <AvatarMui text={localDoctor?.firstName + " " + localDoctor?.lastName} />
                    <span className="absolute -bottom-2.5 left-1/2 -translate-x-1/2 px-2 py-0.5 bg-emerald-100 text-emerald-500 text-xs font-semibold rounded-xl">
                      Actif
                    </span>
                  </div>
                  <h3 className="mt-6 text-slate-900 text-lg font-semibold">
                    {localDoctor?.firstName} {localDoctor?.lastName}
                  </h3>
                  <span className="text-xs text-slate-400">
                    <i className="uil uil-location-point text-pink-500 pr-1"></i>{" "}
                    {localDoctor?.address}
                  </span>
                  <span className="text-slate-500 text-sm mt-6">
                    {localDoctor?.email} | {localDoctor?.phoneNumber}
                  </span>
                </div>
              </div>
              <div className="relative">
                <h4 className="relative text-[13px] uppercase tracking-[.3px] text-slate-400 font-extrabold mb-8 pb-0.5 before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-gray-200">
                  Jour de travail
                </h4>
                <div className="space-y-5">
                  <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                    Lundi : 07h - 18h
                  </div>
                  <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                    Jeudi : 18h - 00h
                  </div>
                  <div className="relative text-sm text-slate-600 pl-6 before:absolute before:left-0 before:top-1.5 before:h-2 before:w-2 before:bg-blue-500 before:rounded-full after:absolute after:left-[3px] after:top-[19px] after:h-[22px] after:w-[1px] after:bg-slate-400 after:last:hidden">
                    vendredi : 18h - 00h
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalUI>
        <div className="px-4 md:px-10 py-28">
          <div className="space-y-8">
            <h3 className="text-lg sm:text-2xl text-slate-700 font-semibold">
              Liste des médecins
            </h3>
            <button
              onClick={() => setOpenFormModal(true)}
              className="lc-modal-show flex items-center text-green-500 hover:bg-green-100 pr-4 hover:pl-4 py-2 rounded-md transition-all ease-in-out duration-300"
            >
              <i className="bx bxs-plus-circle pr-2"></i>
              <span className="text-sm">Nouveau docteur</span>
            </button>
            <div className="flex flex-wrap md:flex-nowrap items-center justify-between">
              <label className="relative w-full md:w-96">
                <span className="absolute left-2 top-[56%] -translate-y-2/4 text-slate-500 text-xl">
                  <i className="bx bx-search"></i>
                </span>
                <input
                  type="text"
                  className="w-full text-sm sm:text-base text-slate-500 pl-8 pr-6 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md"
                  placeholder="Recherche"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
              <div className="w-full md:w-52 mt-4 md:mt-0 flex justify-end">
                <button className="lc-filter flex items-center text-gray-500 hover:bg-gray-200 border px-[15px] h-10 py-1.5 rounded-md transition-all ease-in-out duration-300">
                  <i className="bx bx-filter text-lg pr-1"></i>
                  <span className="text-base">Filter</span>
                </button>
              </div>
            </div>
            <div className="hidden lc-filter-container">
              <div className="block md:flex md:items-center md:justify-between md:space-x-4 space-y-4 md:space-y-0">
                <label className="block grow basis-full">
                  <span className="block text-sm font-semibold text-slate-700">
                    Filter 1
                  </span>
                  <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                    <option value="1">patient-1</option>
                    <option value="2">patient-2</option>
                    <option value="3">patient-3</option>
                  </select>
                </label>
                <label className="block grow basis-full">
                  <span className="block text-sm font-semibold text-slate-700">
                    Filter 2
                  </span>
                  <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                    <option value="1">patient-1</option>
                    <option value="2">patient-2</option>
                    <option value="3">patient-3</option>
                  </select>
                </label>
                <label className="block grow basis-full">
                  <span className="block text-sm font-semibold text-slate-700">
                    Filter 3
                  </span>
                  <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                    <option value="1">patient-1</option>
                    <option value="2">patient-2</option>
                    <option value="3">patient-3</option>
                  </select>
                </label>
                <label className="block grow basis-full">
                  <span className="block text-sm font-semibold text-slate-700">
                    Filter 4
                  </span>
                  <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                    <option value="1">patient-1</option>
                    <option value="2">patient-2</option>
                    <option value="3">patient-3</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="block w-full overflow-x-auto rounded-md">
              <table className="w-full mt-6">
                <tbody>
                  <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                    <td className="px-3 py-3">Images</td>
                    <td className="px-3 py-3">Prénom</td>
                    <td className="px-3 py-3">Nom</td>
                    <td className="px-3 py-3">Status</td>
                    <td className="px-3 py-3">Email</td>
                    <td className="px-3 py-3"></td>
                  </tr>
                  {data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).filter(user => user.roleName === roles.ROLE_DOCTOR).filter(user => user?.firstName.toLowerCase().includes(search.toLocaleLowerCase()) || user?.lastName.toLowerCase().includes(search.toLocaleLowerCase())).map((user, idx) => (
                    <tr
                      key={idx}
                      className="group/table text-xs sm:text-sm text-slate-500 font-medium hover:bg-blue-50 transition-all ease-in-out duration-300"
                    >
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                        {/* <img
                          className="h-10 w-10 ring-2 ring-offset-2 ring-gray-200 group-hover/table:ring-blue-500 rounded-full transition-all ease-in-out duration-300"
                          src="./images/profil-1.png"
                          alt=""
                        /> */}
                        <AvatarMui text={user?.firstName + " " + user?.lastName} />
                      </td>
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                        {user?.firstName}
                      </td>
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                        {user?.lastName}
                      </td>
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                        <span className="px-1.5 py-0.5 bg-emerald-100 text-emerald-500 text-xs font-semibold rounded">
                          Actif
                        </span>
                      </td>
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">
                        {user?.email}
                      </td>
                      <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                        <div className="flex items-center space-x-1">
                          <button
                            onClick={() => {
                              setLocalDoctor(user)
                              setIsOpen(true)
                            }}
                            className="lc-modal-show flex items-center justify-center h-8 w-8 hover:bg-gray-200 hover:text-slate-500 text-lg rounded-full transition-all ease-in-out duration-300"
                          >
                            <i className="uil uil-eye"></i>
                          </button>
                          <button className="flex items-center justify-center h-8 w-8 hover:bg-amber-100 hover:text-amber-500 text-lg rounded-full transition-all ease-in-out duration-300">
                            <i className="bx bx-edit-alt"></i>
                          </button>
                          <button className="flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300">
                            <i className="bx bx-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className="lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 m-0-important opacity-0 pointer-events-none z-[99999] transition-all ease-in-out duration-300"
              data-lc-target="profile-1"
            ></div>
            <div
              className="lc-modal fixed left-1/2 top-1/2 h-full sm:h-auto w-full sm:max-w-xl bg-white m-0-important opacity-0 pointer-events-none z-[999999] -translate-x-1/2 -translate-y-1/2 rounded-none sm:rounded-md transition-all ease-in-out duration-300"
              data-lc-target="newDoctor"
            >
              <span
                className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300"
                data-lc-target="#newDoctor"
              >
                <i className="bx bx-x"></i>
              </span>
              <ModalUI
                  isOpen={openFormModal}
                  onClose={() => setOpenFormModal(false)}
                  styles={{height: "80%"}}
                >
              <form className="h-full py-5">
                <h2 className="text-lg text-slate-700 font-medium text-center mb-8">
                  Enregistrer un medecin
                </h2>
                <div className="pb-5 px-5 h-full max-h-[calc(100%-10%)] sm:max-h-[500px] overflow-auto">
                  <div>
                    {errors.length > 0 &&
                      errors.map((error, idx) => (
                        <div style={{ marginBottom: 2 }}>
                          <Alert key={idx} message={error.message} />
                        </div>
                      ))}
                  </div>
                  <div className="space-y-4 sm:space-y-6">
                    <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                      <label className="block w-auto sm:grow sm:basis-full">
                        <span className="block text-sm font-medium text-slate-500">
                          Prénom
                        </span>
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                      <label className="block w-auto sm:grow sm:basis-full">
                        <span className="block text-sm font-medium text-slate-500">
                          Nom
                        </span>
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                    </div>
                    <label className="relative block">
                      <span className="block text-sm font-medium text-slate-500">
                        Email
                      </span>
                      <span className="absolute top-[70%] right-0 pr-2 text-slate-500 text-xl -translate-y-1/2">
                        <i className="bx bx-envelope"></i>
                      </span>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                      />
                    </label>
                    <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                      <label className="block sm:grow sm:basis-full">
                        <span className="block text-sm font-medium text-slate-500">
                          Nom d'utilisateur
                        </span>
                        <input
                          type="text"
                          name="userName"
                          onChange={handleChange}
                          className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                      <label className="block sm:grow sm:basis-full">
                        <span className="block text-sm font-medium text-slate-500">
                          Tél
                        </span>
                        <input
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                    </div>
                    <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                      <label className="relative block sm:grow sm:basis-full lc-form-group">
                        <span className="block text-sm font-medium text-slate-500">
                          Mot de passe
                        </span>
                        <span className="lc-form-pass absolute top-[70%] right-0 pr-2 cursor-pointer text-slate-500 text-xl -translate-y-1/2">
                          <i className="uil uil-eye-slash"></i>
                        </span>
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          className="lc-form-field w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                      <label className="block sm:grow sm:basis-full">
                        <span className="block text-sm font-medium text-slate-500">
                          Adresse
                        </span>
                        <input
                          type="text"
                          name="address"
                          onChange={handleChange}
                          className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"
                        />
                      </label>
                    </div>
                    {/* <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 md:space-x-9">
                      <div className="shrink-0">
                        <img
                          className="h-20 w-20 sm:h-24 sm:w-24 object-cover rounded-full ring-2 ring-offset-4 ring-gray-200 hover:ring-green-200 transition-all ease-in-out duration-300"
                          src="./images/profil-2.png"
                          alt="Current profile photo"
                        />
                      </div>
                      <label className="block">
                        <span className="sr-only">Choose profile photo</span>
                        <input
                          type="file"
                          className="block w-full text-xs sm:text-sm text-slate-500 file:text-xs sm:file:text-sm file:font-semibold file:py-2 file:px-4 file:bg-green-50 file:text-green-700 file:rounded-full file:border-0 file:mr-4 hover:file:bg-green-100"
                        />
                      </label>
                    </div> */}
                    <button
                      onClick={handleSubmit}
                      className="w-full bg-blue-500 hover:bg-blue-600 shadow-md text-white text-base sm:text-lg font-medium py-2 sm:py-2.5 rounded-md transition-all duration-300"
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </form>
              </ModalUI>
            </div>
            <div
              className="lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 m-0-important opacity-0 pointer-events-none z-[99999] transition-all ease-in-out duration-300"
              data-lc-target="newDoctor"
            ></div>
            <Pagination data={users.filter(user => (user.roleName === roles.ROLE_DOCTOR)).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(user => user?.firstName.toLowerCase().includes(search.toLocaleLowerCase()) || user?.lastName.toLowerCase().includes(search.toLocaleLowerCase()))} setData={setData} />
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

const mapStateToProps = ({ usersReducer }) => ({
  users: usersReducer.users,
  getting_users: usersReducer.getting_users,
  errors: usersReducer.errors,
});

export default connect(mapStateToProps)(PersonnelMedecin);
