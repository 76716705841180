/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import DashboardWrapper from "../Layout/DashboardWrapper";
import { createActs, fetchAllActs, deleteAct } from "../../redux/acts/actions"
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Others/Alert";
import DialogMui from "../material/Dailog";
import ModalUI from "../material/Modal";
import Select from "react-select";
import Pagination from "../Others/Pagination";

const Examen = () => {
  const dispatch = useDispatch();

  const allActs = useSelector(state => state.actsReducer);
  const fetch_acts_status = useSelector(state => state.actsReducer.fetch_acts_status);
  const create_acts_status = useSelector(state => state.actsReducer.create_acts_status);
  const delete_act_status = useSelector(state => state.actsReducer.delete_act_status);
  const errors = useSelector(state => state.actsReducer.errors);

  const [actsList, setActsList] = useState([]);
  const [addAct, setAddAct] = useState({
    actsTypeName: "Consultation",
    basePrice: 0,
    description: "",
    label: ""
  })
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (allActs.acts.length === 0) {
      dispatch(fetchAllActs({}));
    }
    setActsList(allActs.acts);
  }, [allActs.acts])

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddAct({ ...addAct, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createActs({ ...addAct, basePrice: parseFloat(addAct.basePrice), actsTypeName: addAct.actsTypeName.toUpperCase() }));
    setAddAct({
      actsTypeName: "Consultation",
      basePrice: 0,
      description: "",
      label: ""
    })
  }
  const handleDelete = (id) => {
    setActId(id)
  }
console.log("allActs.acts: ", allActs.acts)
  const [openDialog, setOpenDialog] = useState(false)
  const [actId, setActId] = useState()
  const [openFormModal, setOpenFormModal] = useState(false)
  const [data, setData] = useState(allActs.acts)

  return (
    <>
      <DashboardWrapper showSpinner={(fetch_acts_status || create_acts_status || delete_act_status)}>
        <DialogMui
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onCancel={() => setOpenDialog(false)}
          onConfirm={() => {
            dispatch(deleteAct(actId))
            setOpenDialog(false)
          }}
         />
          <div className="px-4 md:px-10 py-28">
            <div className="space-y-8">
              <h3 className="text-lg sm:text-2xl text-slate-700 font-semibold">Liste des Acts</h3>
              <button onClick={() => setOpenFormModal(true)} className="lc-modal-show flex items-center text-green-500 hover:bg-green-100 pr-4 hover:pl-4 py-2 rounded-md transition-all ease-in-out duration-300"><i className='bx bxs-plus-circle pr-2' ></i><span className="text-sm">Ajouter Acts</span></button>
              {/* <!-- <div className="flex w-full md:w-96">
                <label className="block grow basis-full">
                  <span className="block text-sm font-medium text-slate-500">Type examen</span>
                  <select className="w-full text-sm sm:text-base text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                    <option value="Clinique">Clinique</option>
                    <option value="Complémentaire">Complémentaire</option>
                  </select>
                </label>
              </div> --> */}
              <div className="flex flex-wrap md:flex-nowrap items-center justify-between">
                <label className="relative w-full md:w-96">
                  <span className="absolute left-2 top-[56%] -translate-y-2/4 text-slate-500 text-xl"><i className='bx bx-search' ></i></span>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="w-full text-sm sm:text-base text-slate-500 pl-8 pr-6 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 rounded-md" placeholder="Recherche" />
                </label>
                <div className="w-full md:w-52 mt-4 md:mt-0 flex justify-end">
                  <button className="lc-filter flex items-center text-gray-500 hover:bg-gray-200 border px-[15px] h-10 py-1.5 rounded-md transition-all ease-in-out duration-300"><i className='bx bx-filter text-lg pr-1' ></i><span className="text-base">Filter</span></button>
                </div>
              </div>
              <div className="hidden lc-filter-container">
                <div className="block md:flex md:items-center md:justify-between md:space-x-4 space-y-4 md:space-y-0">
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 1</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 2</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 3</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                  <label className="block grow basis-full">
                    <span className="block text-sm font-semibold text-slate-700">Filter 4</span>
                    <select className="w-full text-slate-500 text-sm mt-1 border-none ring-1 ring-gray-200 focus:ring-gray-200 focus:shadow-md placeholder-slate-400 rounded-md">
                      <option value="1">patient-1</option>
                      <option value="2">patient-2</option>
                      <option value="3">patient-3</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="block w-full overflow-x-auto rounded-md">
                <table className="w-full mt-6">
                  <tbody>
                    <tr className="bg-slate-50 text-xs sm:text-sm text-slate-400 font-medium">
                      <td className="px-3 py-3">Acts</td>
                      <td className="px-3 py-3">Description</td>
                      <td className="px-3 py-3 text-right">Prix</td>
                      <td className="px-3 py-3"></td>
                    </tr>
                    {
                      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(act => act?.label.toLowerCase().includes(search.toLocaleLowerCase())).map((elt) => {
                        return (
                          <tr key={elt.id} className="group/table text-xs sm:text-sm text-slate-500 font-medium hover:bg-blue-50 transition-all ease-in-out duration-300">
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap">{elt.label}</td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">{elt.description ? elt.description : "aucune description"}</td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100 whitespace-nowrap text-right text-blue-500">{elt.price}f.cfa</td>
                            <td className="px-3 py-3 sm:py-6 border-b border-b-gray-100">
                              <div className="flex items-center justify-end space-x-1">
                                <button className="flex items-center justify-center h-8 w-8 hover:bg-amber-100 hover:text-amber-500 text-lg rounded-full transition-all ease-in-out duration-300"><i className='bx bx-edit-alt' ></i></button>
                                <button className="flex items-center justify-center h-8 w-8 hover:bg-pink-100 hover:text-pink-500 text-lg rounded-full transition-all ease-in-out duration-300" onClick={() => {
                                  handleDelete(elt.id)
                                  setOpenDialog(true)
                                }}><i className='bx bx-trash' ></i></button>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="lc-modal fixed left-1/2 top-1/2 h-full sm:h-auto w-full sm:max-w-xl bg-white m-0-important opacity-0 pointer-events-none z-[999999] -translate-x-1/2 -translate-y-1/2 rounded-none sm:rounded-md transition-all ease-in-out duration-300" data-lc-target="newExamen">
                <span className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300" data-lc-target="#newExamen"><i className='bx bx-x'></i></span>
                <ModalUI
                  isOpen={openFormModal}
                  onClose={() => setOpenFormModal(false)}
                  styles={{height: "80%"}}
                >
                <form className="h-full py-5" onSubmit={handleSubmit}>
                  <h2 className="text-lg text-slate-700 font-medium text-center mb-8">Enregistrer un acts</h2>
                  <div className="pb-5 px-5 h-full max-h-[calc(100%-10%)] sm:max-h-[500px] overflow-auto">
                    <div>
                      {errors.length > 0 && (
                        errors.map((error, idx) => <div style={{ marginBottom: 2 }}>
                          <Alert key={idx} message={error.message}/>
                        </div>)
                      )}
                    </div>
                    <div className="space-y-4 sm:space-y-6">
                      <label className="relative block">
                        <span className="block text-sm font-medium text-slate-500">Nom examen</span>
                        <input type="text" name="label" value={addAct.label} onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                      </label>
                      <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4">
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Prix</span>
                          <input type="number" value={addAct.basePrice} name="basePrice" onChange={handleChange} className="w-full text-slate-500 py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md" />
                        </label>
                        <label className="block sm:grow sm:basis-full">
                          <span className="block text-sm font-medium text-slate-500">Acts type</span>
                          <select value={addAct.actsTypeName} name="actsTypeName" onChange={handleChange} className="w-full text-slate-500 mt-1 py-2 border-none ring-1 ring-gray-200 focus:ring-gray-200 placeholder-slate-400 rounded-md">
                            <option value="Consultation">Consultation</option>
                            <option value="Exam">Exam</option>
                          </select>
                        </label>
                      </div>
                      <label className="relative block">
                        <span className="block text-sm font-medium text-slate-500">Description</span>
                        <textarea value={addAct.description} name="description" onChange={handleChange} className="w-full text-slate-500 resize-none py-2 pl-3 pr-9 bg-gray-100 focus:bg-transparent border-none focus:ring-1 focus:ring-gray-200 placeholder-slate-400 mt-1 rounded-md"></textarea>
                      </label>
                      <button onClick={(e) => {
                        handleSubmit(e)
                        setOpenFormModal(false)
                      }} className="w-full bg-blue-500 hover:bg-blue-600 shadow-md text-white text-base sm:text-lg font-medium py-2 sm:py-2.5 rounded-md transition-all duration-300">Enregistrer</button>
                    </div>
                  </div>
                </form>
                </ModalUI>
              </div>
              <div className="lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 m-0-important opacity-0 pointer-events-none z-[99999] transition-all ease-in-out duration-300" data-lc-target="newExamen"></div>
              <Pagination data={allActs.acts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(act => act?.label.toLowerCase().includes(search.toLocaleLowerCase()))} setData={setData} maxDisplay={10} />
            </div>
          </div>
      </DashboardWrapper>
    </>
  );
};
export default Examen;
