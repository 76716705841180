export const CREATE_ACTS_REQUEST = 'CREATE_ACTS_REQUEST';
export const CREATE_ACTS_SUCCESS = 'CREATE_ACTS_SUCCESS';
export const CREATE_ACTS_FAILURE = 'CREATE_ACTS_FAILURE';

export const FETCH_ACTS_REQUEST = 'FETCH_ACTS_REQUEST';
export const FETCH_ACTS_SUCCESS = 'FETCH_ACTS_SUCCESS';
export const FETCH_ACTS_FAILURE = 'FETCH_ACTS_FAILURE';

export const DELETE_ACTS_REQUEST = 'DELETE_ACTS_REQUEST';
export const DELETE_ACTS_SUCCESS = 'DELETE_ACTS_SUCCESS';
export const DELETE_ACTS_FAILURE = 'DELETE_ACTS_FAILURE';

export const GET_ACTS_PRICE_INSURER_FILTER_REQUEST = "GET_ACTS_PRICE_INSURER_FILTER_REQUEST"
export const GET_ACTS_PRICE_INSURER_FILTER_SUCCESS = "GET_ACTS_PRICE_INSURER_FILTER_SUCCESS"
export const GET_ACTS_PRICE_INSURER_FILTER_FAILURE = "GET_ACTS_PRICE_INSURER_FILTER_FAILURE"

export const CREATE_ACTS_PRICE_INSURER_REQUEST = "CREATE_ACTS_PRICE_INSURER_REQUEST"
export const CREATE_ACTS_PRICE_INSURER_SUCCESS = "CREATE_ACTS_PRICE_INSURER_SUCCESS"
export const CREATE_ACTS_PRICE_INSURER_FAILURE = "CREATE_ACTS_PRICE_INSURER_FAILURE"

export const UPDATE_ACTS_PRICE_INSURER_REQUEST = "UPDATE_ACTS_PRICE_INSURER_REQUEST"
export const UPDATE_ACTS_PRICE_INSURER_SUCCESS = "UPDATE_ACTS_PRICE_INSURER_SUCCESS"
export const UPDATE_ACTS_PRICE_INSURER_FAILURE = "UPDATE_ACTS_PRICE_INSURER_FAILURE"

export const DELETE_ACTS_PRICE_INSURER_REQUEST = "DELETE_ACTS_PRICE_INSURER_REQUEST"
export const DELETE_ACTS_PRICE_INSURER_SUCCESS = "DELETE_ACTS_PRICE_INSURER_SUCCESS"
export const DELETE_ACTS_PRICE_INSURER_FAILURE = "DELETE_ACTS_PRICE_INSURER_FAILURE"