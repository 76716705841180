import config from '../../config';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

/**
 *
 * @param {*} payload
 * @returns user details
 */
export const createDiseases = async (payload) => {

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/diseases", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('CreateDiseaseServiceErr: ', error);
  }
}

export const getDiseasesByFilter = async (payload, token) => {
  // var myHeaders = new Headers();
  // myHeaders.append("Authorization", "Bearer " + token);
  // myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(payload);

  var requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: raw,
    redirect: 'follow'
  };
  try {

    const request = await fetch(config.API + "/api/diseases/get-filter", requestOptions)
    const response = await request.json()
    return response
  }
  catch (err) {
    console.error('GetDiseasesByFilterServiceErr: ', err);
  }
}

export const updateDisease = async ({data, id}) => {

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/diseases/" + id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('updateDiseaseServiceErr: ', error);
  }
}

export const deleteDiseases = async (id) => {

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/diseases/" + id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('DeleteDiseaseServiceErr: ', error);
  }
}
