import { toast } from "react-toastify";
import { put, takeLatest } from 'redux-saga/effects';
import {
  createUser as createUserService,
  getUserProfile as getUserProfileService,
  getUsersByFilter as getUsersByFilterService,
  getUsers as getUsersService,
  loginUser as loginUserService,
  updateUser as updateUserService,
  getPatientCard as getPatientCardService
} from '../../services/users';
import { removeStorageItem, setStoragePatient, setToken } from '../../utils/storageUtils';
import * as types from './types';
import { adaptResponseError } from "../../utils/utils";

/**
 * @description login a User Account
 */

function* loginUser({ payload }) {

  try {
    const result = yield loginUserService(payload);
    console.log("DATA_SAGA: ", result)
    if (result?.success) {
      toast.success(result?.message)
      yield put({ type: types.LOGIN_USER_SUCCESS, payload: result.data })
      // store le token dans le localstorage
      localStorage.setItem("userdata", JSON.stringify(result.data))
      setToken(result.data.jwtAuthenticationResponse.accessToken)
      return window.location.replace('/users')
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.LOGIN_USER_FAILURE, payload: [] })
    }
  } catch (error) {
    console.error('LoginSagaErr: ', error);
  }
}

function* getUserProfile() {
  const userdata = JSON.parse(localStorage.getItem("userdata"))

  if(!userdata) {
    return window.location.replace('/login')
  }

  try {
    const result = yield getUserProfileService(userdata?.user?.id);
    console.log("USER_SAGA: ", result)
    if (result?.success) {
      // toast.success(result?.message)
      console.log("RESULT.data: ", result.data)
      yield put({ type: types.GET_USER_PROFILE_SUCCESS, payload: result.data })
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.GET_USER_PROFILE_FAILURE, payload: [] })
    }
  } catch (error) {
    console.error('GetUsersSagaErr: ', error);
  }
}

function* getUsers() {

  try {
    const result = yield getUsersService();
    console.log("USERS_SAGA: ", result)
    if (result?.success) {
      // toast.success(result?.message)
      console.log("RESULT.data: ", result.data)
      yield put({ type: types.GET_USERS_SUCCESS, payload: result.data })
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.GET_USERS_FAILURE, payload: [] })
    }
  } catch (error) {
    console.error('GetUsersSagaErr: ', error);
  }
}

function* getUsersByFilter({ payload }) {

  try {
    const result = yield getUsersByFilterService(payload);
    console.log("USERS_SAGA: ", result)
    if (result?.success) {
      // toast.success(result?.message)
      console.log("RESULT.data: ", result.data)
      yield put({ type: types.GET_USERS_BY_FILTER_SUCCESS, payload: result.data })
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.GET_USERS_BY_FILTER_FAILURE, payload: [] })
    }
  } catch (error) {
    console.error('GetUsersByFilterSagaErr: ', error);
  }
}

function* createUser({ payload }) {
  try {
    const result = yield createUserService(payload);
    console.log("CREATE_USER_SAGA: ", result)
    if (result?.success) {
      console.log("RESULT.data: ", result.data)
      toast.success(result?.message)
      yield put({ type: types.CREATE_USER_SUCCESS, payload: result.data })
      yield put({ type: types.GET_USERS_REQUEST })
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.CREATE_USER_FAILURE, payload: adaptResponseError(result) })
    }
  } catch (error) {
    console.error("CreateUserSagaErr: ", error)
  }
}

function* updateUser({ payload }) {
  try {
    const result = yield updateUserService(payload);
    console.log("UPDATE_USER_SAGA: ", result)
    if (result?.success) {
      console.log("RESULT.data: ", result.data)
      toast.success(result?.message)
      if(result?.data?.roleName === "ROLE_PATIENT") {
        setStoragePatient(result?.data)
      }
      yield put({ type: types.UPDATE_USER_SUCCESS, payload: result.data })
      yield put({ type: types.GET_USERS_REQUEST })
    }
    if (!result.success) {
      toast.error(result?.message)
      yield put({ type: types.UPDATE_USER_FAILURE, payload: adaptResponseError(result) })
    }
  } catch (error) {
    console.error("UpdateUserSagaErr: ", error)
  }
}

function* logoutUser(){
  yield removeStorageItem("userdata")
  yield removeStorageItem("Bearer")
  toast.success("User logged out successfully")
  return window.location.replace('/login')
}

function* getPatientCard({ payload }) {
  yield getPatientCardService(payload)
}

export default function* AuthSaga() {
  yield takeLatest(types.LOGIN_USER_REQUEST, loginUser);
  yield takeLatest(types.GET_USERS_REQUEST, getUsers);
  yield takeLatest(types.GET_USER_PROFILE_REQUEST, getUserProfile);
  yield takeLatest(types.GET_USERS_BY_FILTER_REQUEST, getUsersByFilter);
  yield takeLatest(types.CREATE_USER_REQUEST, createUser);
  yield takeLatest(types.LOGOUT_USER_REQUEST, logoutUser);
  yield takeLatest(types.UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(types.GET_PATIENT_CARD_REQUEST, getPatientCard);
}