export const roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_DOCTOR: "ROLE_DOCTOR",
  ROLE_INSURER: "ROLE_INSURER",
  ROLE_PATIENT: "ROLE_PATIENT",
  ROLE_SECRETARY: "ROLE_SECRETARY"
}

export const rolesPath = {
  ROLE_ADMIN: "admin",
  ROLE_DOCTOR: "admin",
  ROLE_INSURER: "insurer",
  ROLE_PATIENT: "patient",
  ROLE_SECRETARY: "admin"
}