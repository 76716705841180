import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import {
  uploadFile as uploadFileService
} from "../../services/uploads";
import { getToken } from "../../utils/storageUtils";
import * as types from "./types";

const token = getToken();

function* uploadFile({ payload }) {
  try {
    const result = yield uploadFileService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({
        type: types.UPLOAD_FILE_CONSENTEMENT_SUCCESS,
        payload: result?.data || [],
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.UPLOAD_FILE_CONSENTEMENT_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("UploadFileSaga: ", error);
  }
}

export default function* UploadsSaga() {
  yield takeLatest(types.UPLOAD_FILE_CONSENTEMENT_REQUEST, uploadFile);
}
