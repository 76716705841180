import config from '../../config';
import { roles, rolesPath } from '../../constants/roles';
import { logoutHelper } from '../../utils/logoutHelper';

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');

/**
 *
 * @param {*} payload
 * @returns user details
 */
export const loginUser = async (payload) => {

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users/signin", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('LoginServiceErr: ', error);
  }
}

export const getUsers = async () => {
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('GetUsersServiceErr: ', error);
  }
}

export const getUsersByFilter = async (payload) => {
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users/get-filter", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('GetUsersByFilterServiceErr: ', error);
    logoutHelper()
  }
}

export const getUserProfile = async (id) => {
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users/" + id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('GetUserServiceErr: ', error);
    logoutHelper()
  }
}

export const registerInsurer = async (payload) => {

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users/insurer", requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('LoginServiceErr: ', error);
  }
}

// Patients services
export const createUser = async (payload) => {

  console.log("PAYLOAD: ", payload)
  let data = {}
  if (payload.roleName === roles.ROLE_INSURER) {
    data = { ...payload, name: payload?.name ? payload?.name: payload?.userName}
  }else{
    data = payload
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow'
  };

  try {
    const request = await fetch(`${config.API}/api/users/${rolesPath[payload.roleName]}`, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('CreateUserServiceErr: ', error);
  }
}

export const updateUser = async (payload) => {

  console.log("PAYLOAD: ", payload)

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(payload),
    redirect: 'follow'
  };

  try {
    const request = await fetch(`${config.API}/api/users/${payload.id}`, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('UpdateUserServiceErr: ', error);
  }
}
export const getPatientCard = async (id) => {
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const request = await fetch(config.API + "/api/users/patient-card-pdf/" + id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result)
    return result;
  } catch (error) {
    console.error('GetPatientServiceErr: ', error);
  }
}

export const logout = () => {}