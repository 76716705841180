import { toast } from "react-toastify";
import { put, takeLatest } from "redux-saga/effects";
import {
  getInvoiceByFilter as getInvoiceByFilterService,
  createInvoice as createInvoiceService,
} from "../../services/invoices";
import { getStoragePatient, getToken } from "../../utils/storageUtils";
import * as types from "./types";
import { adaptResponseError } from "../../utils/utils";

const token = getToken();
const storagePatient = getStoragePatient();

function* createInvoice({ payload }) {
  try {
    const result = yield createInvoiceService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      toast.success(result?.message);
      yield put({
        type: types.CREATE_INVOICE_SUCCESS,
        payload: result?.data || [],
      });
      yield put({
        type: types.GET_INVOICE_BY_FILTER_REQUEST,
        payload: { patientId: storagePatient?.id },
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({
        type: types.CREATE_INVOICE_FAILURE,
        payload: adaptResponseError(result),
      });
    }
  } catch (error) {
    console.error("GetInvoicesByFilterSagaErr: ", error);
  }
}

function* getInvoicesByFilter({ payload }) {
  try {
    const result = yield getInvoiceByFilterService(payload, token);
    console.log("DATA_SAGA: ", result);
    if (result?.success) {
      // toast.success(result?.message);
      yield put({
        type: types.GET_INVOICE_BY_FILTER_SUCCESS,
        payload: result?.data || [],
      });
    }
    if (!result.success) {
      toast.error(result?.message);
      yield put({ type: types.GET_INVOICE_BY_FILTER_FAILURE, payload: [] });
    }
  } catch (error) {
    console.error("GetInvoicesByFilterSagaErr: ", error);
  }
}

export default function* InvoicesSaga() {
  yield takeLatest(types.CREATE_INVOICE_REQUEST, createInvoice);
  yield takeLatest(types.GET_INVOICE_BY_FILTER_REQUEST, getInvoicesByFilter);
}
