import config from "../../config";

var myHeaders = new Headers();
const token = localStorage.getItem("Bearer");
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${token && token}`);

/**
 *
 * @param {*} payload
 * @returns consultation details
 */
export const createActs = async (payload) => {
  // const token = localStorage.getItem('Bearer');
  if (!token) {
    window.location.href = "/login";
  }
  //myHeaders.append('Authorization', `Bearer ${token}`)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(config.API + "/api/acts", requestOptions);
    const result = await request.json();

    return result;
  } catch (error) {
    console.error("CreateActsServiceErr: ", error);
  }
};

export const filterActs = async (payload) => {
  //const token = localStorage.getItem('Bearer');
  if (!token) {
    window.location.href = "/login";
  }
  //myHeaders.append('Authorization', `Bearer ${token}`)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts/get-filter",
      requestOptions
    );
    const result = await request.json();
    console.log("fetch result", result);
    return result;
  } catch (error) {
    console.error("CreateActsServiceErr: ", error);
  }
};

export const getActsPriceInsurerFilter = async (payload, token) => {
  //myHeaders.append('Authorization', `Bearer ${token}`)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer/get-filter",
      requestOptions
    );
    const result = await request.json();
    return result;
  } catch (error) {
    console.error("GetActsPriceInsuserServiceErr: ", error);
  }
};

export const createActsPriceInsurer = async (payload, token) => {
  //myHeaders.append('Authorization', `Bearer ${token}`)
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer",
      requestOptions
    );
    const result = await request.json();
    return result;
  } catch (error) {
    console.error("CreateActsPriceInsuserServiceErr: ", error);
  }
};

export const updateActsPriceInsurer = async (payload, token) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer/" + payload.id,
      requestOptions
    );
    const result = await request.json();
    return result;
  } catch (error) {
    console.error("UpdateActsPriceInsuserServiceErr: ", error);
  }
};

export const deleteActsPriceInsurer = async (payload, token) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer/" + payload.id,
      requestOptions
    );
    const result = await request.json();
    return result;
  } catch (error) {
    console.error("DeleteActsPriceInsuserServiceErr: ", error);
  }
};

export const updateActs = async ({ data, id }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const request = await fetch(config.API + "/api/acts/" + id, requestOptions);
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("UpdateActsServiceErr: ", error);
  }
};

export const deleteActs = async (id) => {
  if (!token) {
    window.location.href = "/login";
  }

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  try {
    const request = await fetch(config.API + "/api/acts/" + id, requestOptions);
    const result = await request.json();
    return result;
  } catch (error) {
    console.error("DeleteActsServiceErr: ", error);
  }
};

// acts insurer price controller
export const createActsInsurer = async (payload) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer",
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("CreateActsInsurerPriceServiceErr: ", error);
  }
};

export const updateActsInsurer = async ({ data, id }) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer/" + id,
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("UpdateActsInsurerPriceServiceErr: ", error);
  }
};

export const deleteActsInsurer = async (id) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
  };

  try {
    const request = await fetch(
      config.API + "/api/acts-price-insurer/" + id,
      requestOptions
    );
    const result = await request.json();
    console.log("Result: ", result);
    return result;
  } catch (error) {
    console.error("DeleteActsInsurerPriceServiceErr: ", error);
  }
};
