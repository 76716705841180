/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/users/actions";
import { getToken } from "../../utils/storageUtils";

const Login = () => {
  const [form, setForm] = useState({
    password: "",
    usernameOrEmail: "",
  });
  const dispatch = useDispatch();
  const token = getToken();

  const handleChange = (e) =>
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form: ", form);
    dispatch(loginUser(form));
  };
  useEffect(() => {
    if (!!token) {
      window.location.replace("/users");
    }
  }, []);

  return (
    <>
      <div className="flex gap-4 px-5">
        <div className="hidden border-b-[12px] border-b-solid border-b-white border-t-[12px] border-t-solid border-t-white md:flex min-h-screen items-center bg-gradient-to-r from-green-700 via-green-600 to-green-500 md:2/6 lg:w-3/6 rounded-[35px]">
          <div className="text-white px-5 lg:px-0 lg:ml-[100px] space-y-8 lg:space-y-[60px]">
            <h2 className="text-3xl lg:text-[45px] font-semibold underline underline-offset-[14px]">
              Bienvenue
            </h2>
          </div>
        </div>

        <div className="min-h-screen flex items-center w-full md:w-4/6 lg:w-3/6 px-5 sm:px-20">
          <form className="w-full">
            <h6 className="text-slate-700 text-4xl font-bold mb-11">Login</h6>

            <label className="relative block overflow-hidden mt-8">
              <input
                type="text"
                name="usernameOrEmail"
                onChange={handleChange}
                className="block peer w-full py-1 pr-4 pl-0 placeholder-slate-400 text-sm text-slate-500 border-none focus:border-transparent outline-none-important shadow-none-important"
                placeholder="Email"
              />

              <span className="absolute right-1 top-2/4 -translate-y-2/4 text-slate-500 text-xl">
                <i className="bx bx-envelope"></i>
              </span>

              <div className="absolute left-0 bottom-0 h-0.5 w-full bg-slate-700 before:absolute before:bottom-0 before:-left-full peer-focus:before:left-0 before:h-0.5 before:w-full before:bg-blue-500 before:transition-[left] before:duration-700 before:ease-linear"></div>
            </label>

            <label className="lc-form-group relative block overflow-hidden mt-8">
              <input
                type="password"
                name="password"
                onChange={handleChange}
                className="lc-form-field block peer w-full py-1 pr-4 pl-0 placeholder-slate-400 text-sm text-slate-500 border-none focus:border-transparent outline-none-important shadow-none-important"
                placeholder="Mot de passe"
              />

              <span className="lc-form-pass absolute right-1 top-2/4 -translate-y-2/4 text-slate-500 text-xl cursor-pointer">
                <i className="uil uil-eye-slash"></i>
              </span>

              <div className="absolute left-0 bottom-0 h-0.5 w-full bg-slate-700 before:absolute before:bottom-0 before:-left-full peer-focus:before:left-0 before:h-0.5 before:w-full before:bg-blue-500 before:transition-[left] before:duration-700 before:ease-linear"></div>
            </label>

            <label className="inline-flex items-center mt-8">
              <input
                type="checkbox"
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50"
              />

              <span className="ml-2 text-sm font-medium text-gray-600">
                Se souvenir de moi
              </span>
            </label>

            <div className="mt-8">
              <button
                onClick={handleSubmit}
                type="submit"
                className="w-full bg-green-500 hover:bg-green-600 shadow-md text-white text-base sm:text-xl font-medium py-2 sm:py-3 rounded-md transition-all duration-300"
              >
                Se connecter
              </button>
            </div>

            <div className="mt-8 text-center">
              <a
                href="#"
                className="text-slate-500 text-sm hover:underline transition-all duration-300"
              >
                Mot de passe oublié ?
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
