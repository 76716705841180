import {all} from 'redux-saga/effects';
import UsersSaga from '../users/saga';
import DrugsSaga from '../drugs/saga';
import ActsSaga from '../acts/saga';
import DiseasesSaga from '../diseases/saga';
import ConsultationsSaga from "../consultations/saga"
import InvoicesSaga from '../invoice/saga';
import UploadsSaga from '../uploads/saga';

/**
 * @description combine sagas
 */

export default function* Sagas() {
  yield all([
    ActsSaga(),
    UsersSaga(),
    DrugsSaga(),
    DiseasesSaga(),
    ConsultationsSaga(),
    InvoicesSaga(),
    UploadsSaga()
  ]);
}