import * as types from './types';

const INITIAL_STATE = {
  getting_diseases: false,
  diseases: []
};

function DiseasesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_DISEASES_BY_FILTER_REQUEST:
      return {
        ...state,
        getting_diseases: true
      };

    case types.GET_DISEASES_BY_FILTER_SUCCESS:
      console.log("ACTION: ", action)
      return {
        ...state,
        getting_diseases: false,
        diseases: action.payload
      };
    case types.GET_DISEASES_BY_FILTER_FAILURE:
      return {
        ...state,
        getting_diseases: false
      };

    default:
      return state;
  }
}

export default DiseasesReducer;