export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE"

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE"

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const GET_USERS_BY_FILTER_REQUEST = "GET_USERS_BY_FILTER_REQUEST"
export const GET_USERS_BY_FILTER_SUCCESS = "GET_USERS_BY_FILTER_SUCCESS"
export const GET_USERS_BY_FILTER_FAILURE = "GET_USERS_BY_FILTER_FAILURE"

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"

export const SET_CURRENT_PATIENT = "SET_CURRENT_PATIENT"

export const SET_CURRENT_INSURER = "SET_CURRENT_INSURER"

export const GET_PATIENT_CARD_REQUEST = "GET_PATIENT_CARD_REQUEST"
export const GET_PATIENT_CARD_SUCCESS = "GET_PATIENT_CARD_SUCCESS"
export const GET_PATIENT_CARD_FAILURE = "GET_PATIENT_CARD_FAILURE"